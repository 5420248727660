import React from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import UserService from "../../services/UserService";
import { userRoles } from "../../util/constants";

const validEmailRegex = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

class CreateAccount extends React.Component {
  state = {
    password: null,
    email: null,
    fullName: null,
    wasSubmitted: false,
    emailExist: false,
    redirectToLanding: false,
    errors: {
      fullName: "Please enter your full name.",
      email: "Must be a valid email format.",
      password: "Password is required.",
    },
  };

  componentWillUnmount = () => {
    this.setState({
      redirectToLanding: false,
    });
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  checkEmail = async (event) => {
    event.preventDefault();
    const { value } = event.target;
    const service = await UserService.getInstance();
    const response = await service.findByEmail(value);
    if (response.success) {
      this.setState({ emailExist: true });
      return true;
    } else {
      this.setState({ emailExist: false });
      return false;
    }
  };

  saveUser = async (user) => {
    await this.context.register(user);
    this.props.history.replace("/tournaments");
  };

  validate = () => {
    const { fullName, email, password, errors, emailExist } = this.state;
    Object.entries(errors).forEach((el) => {
      const prop = el[0];
      switch (prop) {
        case "fullName":
          errors.fullName =
            fullName && fullName.trim().length > 0
              ? ""
              : "Please enter your full name.";
          break;
        case "email": {
          if (email && validEmailRegex.test(email.trim())) {
            errors.email = emailExist ? "Email already exist" : "";
          } else {
            errors.email = "Must be a valid email format.";
          }
          break;
        }
        case "password":
          errors.password =
            password && password.trim().length > 0
              ? ""
              : "Password is required to proceed.";
          break;
        default:
          break;
      }
    });
    const valid = this.validateForm(errors);
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { fullName, email, password, errors } = this.state;
    this.setState({
      wasSubmitted: true,
    });
    if (this.validate(errors)) {
      const user = {
        role: userRoles.coach,
        email: email,
        name: fullName,
        password: password,
      };
      this.saveUser(user);
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="tab-content active-tab-content" id="create-account">
        <ul>
          <form onSubmit={this.handleSubmit} noValidate>
            <li>
              <label>Name</label>
              <input
                name="fullName"
                type="text"
                placeholder="Enter your name"
                onChange={this.handleChange}
                noValidate
              />
              {errors.fullName.length > 0 && this.state.wasSubmitted && (
                <span className="error">{errors.fullName}</span>
              )}
            </li>
            <li>
              <label>Email Address</label>
              <input
                name="email"
                type="text"
                placeholder="Enter your email address"
                onChange={this.handleChange}
                noValidate
                onBlur={this.checkEmail}
              />
              {errors.email.length > 0 && this.state.wasSubmitted && (
                <span className="error">{errors.email}</span>
              )}
            </li>
            <li>
              <label>Password</label>
              <input
                name="password"
                type="password"
                placeholder="Enter a secure password"
                onChange={this.handleChange}
                noValidate
              />
              {errors.password.length > 0 && this.state.wasSubmitted && (
                <span className="error">{errors.password}</span>
              )}
            </li>
            <li>
              <button className="btn" onClick={(e) => this.handleSubmit(e)}>
                Create Account
              </button>
            </li>
            <li>
              <p>
                By continuing, you agree to Bulz-i’s{" "}
                <button className="link">Terms and Conditions</button> and{" "}
                <button className="link">Privacy Policy</button>
              </p>
            </li>
          </form>
        </ul>
      </div>
    );
  }
}
CreateAccount.contextType = AuthContext;
export default withRouter(CreateAccount);
