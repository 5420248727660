import axios from "axios";
import { API_ENDPOINT } from "../util/constants";

class ApiService {

  async get(path) {
    let response, success;
    let token = sessionStorage.getItem("jwtToken");

    let config = {
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
    };

    try {
      response = await axios.get(API_ENDPOINT + path, config);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const data = fatal ? null : response.data;
    const headers = fatal ? null : response.headers;

    return { fatal, data, headers, success };
  }

  async getPages(path) {
    let response, success;
    let token = sessionStorage.getItem("jwtToken");
    let page = 0;
    let totalPages = 0;
    let fatal;
    let data;
    let headers;
    let tournamentArray = [];

    do {
      let config = {
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        params: { page: page }
      };
      try {
        response = await axios.get(API_ENDPOINT + path, config);
        totalPages = response.data.totalPages;
        success = true;
      } catch (e) {
        response = e.response;
        success = false;
      }

      fatal = !response;
      data = fatal ? null : response.data;
      headers = fatal ? null : response.headers;
      
      data.content.forEach((item) => {
        if (!tournamentArray.includes(item)) {
          tournamentArray.push(item);
        }
      });

      page++;
    } while (page < totalPages)

    data.content = tournamentArray;

    return { fatal, data, headers, success };
  }

  async post(path, body = {}, config = {}) {
    let response, success;

    let token = sessionStorage.getItem("jwtToken");
    let postConfig;

    if (Object.keys(config).length > 0) {
      postConfig = config;
    } else {
      postConfig = {
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      };
    }

    try {
      response = await axios.post(API_ENDPOINT + path, body, postConfig);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const data = fatal ? null : response.data;
    const headers = fatal ? null : response.headers;

    return { fatal, data, headers, success };
  }

  async loginPost(path, body = {}) {
    let response, success;

    try {
      response = await axios.post(API_ENDPOINT + path, body, this.config);
      sessionStorage.setItem("jwtToken", response.data.token);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const data = fatal ? null : response.data.user;
    const headers = fatal ? null : response.headers;

    return { fatal, data, headers, success };
  }

  async put(path, body = {}) {
    let response, success;

    let token = sessionStorage.getItem("jwtToken");
    let config = {
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    try {
      response = await axios.put(API_ENDPOINT + path, body, config);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const data = fatal ? null : response.data;
    const headers = fatal ? null : response.headers;

    return { fatal, data, headers, success };
  }

  async delete(path) {
    let response, success;

    let token = sessionStorage.getItem("jwtToken");
    let config = {
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    try {
      response = await axios.delete(API_ENDPOINT + path, config);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const data = fatal ? null : response.data;
    const headers = fatal ? null : response.headers;

    return { fatal, data, headers, success };
  }
}

export default ApiService;
