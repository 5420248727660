import React from "react";

const TableRow = (props) => (
  <div className={`row row-small ${props.className}`}>
    <div className="cols w-10">{props.rank}</div>
    <div className="cols w-20">{props.name}</div>
    <div className="cols w-15">{props.gender}</div>
    <div className="cols w-15">{props.id}</div>
    <div className="cols w-20">{props.score}</div>
    <div className="cols w-20">{props.tens}</div>
    {/* <div className="cols w-10">Admin</div> */}
  </div>
);

export const TournamentResults = (props) => {
  const top = [];
  const additional = [];

  props.data.forEach((student) => {
    if (
      student.tournamentSession.sessionId && // Session ID is non-null if student has entered a score.
      top.filter((value) => value.gender === student.gender).length < 3 // Limit top 3 students by gender.
    ) {
      top.push(student);
    } else {
      additional.push(student);
    }
  });

  return (
    <>
      {top.length > 0 && (
        <>
          <h5>{`Podium Results - (Top Shooters - Female & Male)`}</h5>
          <div className="table-view table-separator-spacer">
            <TableRow
              className="head-row head-row-bg"
              rank="Rank"
              name="Archer Name"
              gender="Gender"
              id="Archer ID"
              score="Total Score"
              tens="Tens"
            />
            {top
              .filter((student) => student.gender === "F")
              .map((student, index) => (
                <TableRow
                  key={index}
                  rank={index + 1}
                  name={student.name}
                  gender={student.gender}
                  id={student.externalId}
                  score={student.tournamentSession.totalScore}
                  tens={student.tournamentSession.numTens}
                />
              ))}
            {top
              .filter((student) => student.gender === "M")
              .map((student, index) => (
                <TableRow
                  key={index}
                  rank={index + 1}
                  name={student.name}
                  gender={student.gender}
                  id={student.externalId}
                  score={student.tournamentSession.totalScore}
                  tens={student.tournamentSession.numTens}
                />
              ))}
          </div>
        </>
      )}
      {additional.length > 0 && (
        <>
          <h5>{`Additional Shooters - (Female & Male)`}</h5>
          <div className="table-view table-separator-spacer">
            <TableRow
              className="head-row head-row-bg"
              rank="Rank"
              name="Archer Name"
              gender="Gender"
              id="Archer ID"
              score="Total Score"
              tens="Tens"
            />
            {additional
              .filter((student) => student.gender === "F")
              .map((student, index) => (
                <TableRow
                  key={index}
                  rank={student.tournamentSession.sessionId ? index + 4 : "-"}
                  name={student.name}
                  gender={student.gender}
                  id={student.externalId}
                  score={student.tournamentSession.totalScore}
                  tens={student.tournamentSession.numTens}
                />
              ))}
            {additional
              .filter((student) => student.gender === "M")
              .map((student, index) => (
                <TableRow
                  key={index}
                  rank={student.tournamentSession.sessionId ? index + 4 : "-"}
                  name={student.name}
                  gender={student.gender}
                  id={student.externalId}
                  score={student.tournamentSession.totalScore}
                  tens={student.tournamentSession.numTens}
                />
              ))}
          </div>
        </>
      )}
    </>
  );
};
