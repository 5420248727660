import React from "react";
import alertService from "../services/alert";
import UserService from "../services/UserService";
import { userRoles } from "../util/constants";

const AuthContext = React.createContext({ user: null });

class AuthProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = { user: JSON.parse(sessionStorage.getItem("user")) };

    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.register = this.register.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  signIn = async (user) => {
    const service = await UserService.getInstance();
    const response = await service.signIn(user);
    if (response.success) {
      const user = response.data;
      if (user.role && (user.role === userRoles.superadmin || user.role === userRoles.coach)) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
        this.setState({
          user: response.data,
        });
      } else {
        this.loginFailedAlert();
      }
    } else {
      this.loginFailedAlert();
    }
  };

  register = async (user) => {
    const service = await UserService.getInstance();
    const response = await service.saveUser(user);
    if (response.success) {
      sessionStorage.setItem("user", JSON.stringify(response.data));
      this.setState({
        user: response.data,
      });
    } else {
      this.loginFailedAlert();
    }
  };

  forgotPassword = async (email) => {
    const service = await UserService.getInstance();
    const response = await service.forgotPassword(email);
    if (response.success) {
      this.setState({
        wasSubmitted: true
      });
      this.passwordAlert('Email Sent', 'Check your inbox for a password reset link to follow.');
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      } else {
        this.passwordAlert('Error', 'Unable to send email to this address. Please contact a BULZ•I admin.');
      }
    }
  };

  resetPassword = async (email, token, password) => {
    const service = await UserService.getInstance();
    const response = await service.resetPassword(email, token, password);
    if (response.success) {
      this.setState({
        wasSubmitted: true
      });
      this.passwordAlert('Password Reset', 'You can now login with your new password');
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      } else {
        this.passwordAlert('Error', 'Unable to reset password. Please contact a BULZ•I admin.');      
      }
    }
  };

  passwordAlert = (title, message) => {
    alertService.show({
      title,
      message
    });
  }

  loginFailedAlert = () => {
    alertService.show({
      title: "Login Failed",
      message: "Unable to login, please try again.",
    });
  };

  async logoutAlert() {
    const result = await alertService.show({
      title: "Authentication Error",
      message: 'Unauthorized user, log out'
    });
    if (result) {
      window.location.href = '/';
    }
  }

  signOut = () => {
    sessionStorage.removeItem("user");
    this.setState({
      user: null,
    });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          user: this.state.user,
          signIn: this.signIn,
          signOut: this.signOut,
          register: this.register,
          forgotPassword: this.forgotPassword,
          resetPassword: this.resetPassword
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
