import React from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const validEmailRegex = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

class Login extends React.Component {
  state = {
    password: null,
    email: null,
    fullName: null,
    wasSubmitted: false,
    errors: {
      email: "Must be a valid email format.",
      password: "Password is required to proceed.",
    },
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Must be a valid email format.";
        break;
      case "password":
        errors.password =
          value.trim().length > 0 ? "" : "Password is required to proceed.";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value.trim() });
  };

  handleSubmit = async (event) => {
    this.setState({
      wasSubmitted: true,
    });
    event.preventDefault();
    if (this.validateForm(this.state.errors)) {
      const { email, password } = this.state;
      await this.context.signIn({ email, password });
      this.props.history.replace("/tournaments");
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="tab-content" id="login">
        <ul>
          <form onSubmit={this.handleSubmit} noValidate>
            <li>
              <label>Email Address</label>
              <input
                name="email"
                type="text"
                placeholder="Enter your email address"
                onChange={this.handleChange}
                noValidate
              />
              {errors.email.length > 0 && this.state.wasSubmitted && (
                <span className="error">{errors.email}</span>
              )}
            </li>
            <li>
              <label>Password</label>
              <input
                name="password"
                type="password"
                placeholder="Enter your password"
                onChange={this.handleChange}
                noValidate
              />
              {errors.password.length > 0 && this.state.wasSubmitted && (
                <span className="error">{errors.password}</span>
              )}
            </li>
            <li>
              <button className="btn">Login</button>
            </li>
            <li>
              <Link to="/forgot-password" className="link">Forgot Password?</Link>
            </li>
            <li>
              <p>
                By continuing, you agree to Bulz-i’s{" "}
                <button className="link">Terms and Conditions</button> and{" "}
                <button className="link">Privacy Policy</button>
              </p>
            </li>
          </form>
        </ul>
      </div>
    );
  }
}
Login.contextType = AuthContext;
export default withRouter(Login);
