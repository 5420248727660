import React from "react";
import { render } from "react-dom";
import "./AlertService.css";

class AlertService extends React.Component {
  static create() {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return render(<AlertService />, containerElement);
  }

  resolve;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      showConfirmProps: {}
    };

    this.handlePrimary = this.handlePrimary.bind(this);
    this.handleSecondary = this.handleSecondary.bind(this);
  }

  // Only this if 1 button, or true / confirm response if 2 buttons
  handlePrimary() {
    this.setState({ isOpen: false });
    this.resolve(true);
  }

  handleSecondary() {
    this.setState({ isOpen: false });
    this.resolve(false);
  }

  inDevelopmentAlert(featureName = "This feature") {
    return this.show({
      title: "In Development",
      message: `${featureName} is currently in development.`,
    });
  }

  show(props = {}) {
    const showConfirmProps = { ...props };
    console.log(showConfirmProps);
    this.setState({ isOpen: true, showConfirmProps });
    return new Promise((res) => {
      this.resolve = res;
    });
  }

  update(props = {}) {
    const showConfirmProps = { ...props };
    console.log(showConfirmProps);
    this.setState({ showConfirmProps });
  }

  render() {
    const { isOpen, showConfirmProps } = this.state;
    const { title, message, processed, inserted, updated, errors, errorLines, primary, secondary, isLoading } = showConfirmProps;
    return (
      <div className={!isOpen ? "alert" : "alert active"}>
        <div className="alert-wrapper">
          <h3>{title}</h3>
          <p className="alert-message">{message}</p>
          <p className="data-response">{processed}</p>
          <p className="data-response">{inserted}</p>
          <p className="data-response">{updated}</p>
          <p className="data-response">{errors}</p>
          <p className="error-lines">{errorLines}</p>
          
          {secondary && (
            <button className="btn" onClick={this.handleSecondary}>
              {secondary}
            </button>
          )}
          <button className="btn" disabled={isLoading} onClick={this.handlePrimary}>
            {primary || "Okay"}
          </button>
        </div>
      </div>
    );
  }
}

export default AlertService;
