import React from "react";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class Loading extends React.Component {
  render() {
    return (
      <div className="loadingContainer">
        <Loader
          type="Oval"
          color="#00BFFF"
          height={50}
          width={50}
          // timeout={3000} //3 secs
        />
        <h1 className="loadingTitle">{this.props.title}</h1>
      </div>
    );
  }
}

export default Loading;
