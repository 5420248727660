import React from "react";
import EmptyState from "../common/emptyState/EmptyState";
import Loading from "../common/loading/Loading";
import ErrorState from "../common/errorState/ErrorState";

// import TournamentSchoolsListItem from './TournamentSchoolsListItem';
import TournamentService from "../../services/TournamentService";
import alertService from "../../services/alert";
import SchoolService from "../../services/SchoolService";
import { AuthConsumer } from "../../context/AuthContext";
import { userRoles } from "../../util/constants";

class TournamentSchools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      schools: [],
    };
    this.deleteSchool = this.deleteSchool.bind(this);
  }

  async deleteSchool(schoolId, schoolName) {
    const {
      match: { params },
      tournamentStatus,
    } = this.props;
    const tournamentId = params.tournamentId;
    const service = await TournamentService.getInstance();
    let response;
    let actionCalled = false;
    if (tournamentStatus && tournamentStatus !== "Upcoming") {
      const result = await alertService.show({
        title: "Remove Archer?",
        message: `Removing ${schoolName} from the tournament will also permanently remove all scores that may have been recorded by archers.`,
        primary: "Remove",
        secondary: "Cancel",
      });
      if (result) {
        response = await service.removeSchool(tournamentId, schoolId);
        actionCalled = true;
      }
    } else {
      response = await service.removeSchool(tournamentId, schoolId);
      actionCalled = true;
    }

    if (response && response.success) {
      this.fetchSchools();
    } else if (actionCalled) {
      alertService.show({
        title: "Delete Failed",
        message: "Unable to delete school at this time, please try again.",
      });
    }
  }

  async fetchSchools() {
    const {
      match: { params },
    } = this.props;
    const tournamentId = params.tournamentId;

    const service = await SchoolService.getInstance();
    const response = await service.allSchoolsForTournament(tournamentId);
    const { data } = response;
    if (response.success) {
      this.setState({
        schools: data,
        isLoaded: true,
      });
    } else {
      this.setState({
        isLoaded: true,
        error: true,
      });
    }
  }

  componentDidMount() {
    this.fetchSchools();
  }

  render() {
    const {
      match: { params },
      tournament,
    } = this.props;
    const tournamentId = params.tournamentId;
    const { error, isLoaded, schools } = this.state;
    if (error) {
      return (
        <ErrorState title="An error has occurred loading this content, please try again" />
      );
    } else if (!isLoaded) {
      return <Loading title="Loading..." />;
    } else {
      return (
        <div className="tabs-wrapper">
          <div className="tab-content active-tab-content" id="schools">
            <div className="section-title">
              <h5>Participating Schools</h5>
            </div>
            {Object.keys(schools).map((schoolTitle, index) => {
              const schoolData = schools[schoolTitle];
              return (
                <>
                  <div className="table-view table-separator-spacer">
                    <div className="row">
                      <div className="cols w-100 p-l-0 p-r-0 b-0">
                        <h5>{schoolTitle}</h5>
                      </div>
                    </div>
                    <div className="row row-small head-row head-row-bg">
                      <div className="cols w-15">Rank</div>
                      <div className="cols w-40">Name</div>
                      <div className="cols w-15">Score</div>
                      <div className="cols w-15">Tens</div>
                      <div className="cols w-15">&nbsp;</div>
                    </div>
                    {schoolData.map((school, i) => (
                      <div className="row row-small" key={i}>
                        <div className="cols w-15">{i + 1}</div>
                        <div className="cols w-40">
                          <a
                            href={`/tournaments/${tournamentId}/${school.schoolId}`}
                            params={{ testvalue: "hello" }}
                          >
                            {school.name}
                          </a>
                        </div>
                        <div className="cols w-15">{school.totalScore}</div>
                        <div className="cols w-15">{school.numTens}</div>
                        <AuthConsumer>
                          {({ user }) => (
                            <div className="cols w-15">
                              {user.role === userRoles.superadmin ||
                              user.userId === tournament.ownerId ? (
                                <button
                                  className="btn"
                                  onClick={() =>
                                    this.deleteSchool(
                                      school.schoolId,
                                      school.name
                                    )
                                  }
                                >
                                  Remove
                                </button>
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </div>
                          )}
                        </AuthConsumer>
                      </div>
                    ))}
                  </div>
                </>
              );
            })}
            {this.state.schools.length === 0 && (
              <EmptyState
                title="There are no schools"
                message="There are currently no schools. Come back later"
              />
            )}
          </div>
        </div>
      );
    }
  }
}

export default TournamentSchools;
