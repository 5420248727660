import React from "react";
import { Link } from "react-router-dom";
import TThumb from "../../../assets/images/tournaments-thumb.png";
import * as Functions from "../../../util/helpers";
import { AuthConsumer } from "../../../context/AuthContext";
import { userRoles } from "../../../util/constants";

class TournamentListItem extends React.Component {
  render() {
    return (
      <AuthConsumer>
        {({ user }) => (
          <div className="listing-block">
            {user.role === userRoles.superadmin ||
            user.userId === this.props.tournament.ownerId ? (
              <div className="listing-block-more">
                <button
                  className="listing-block-edit-link"
                  onClick={this.props.edit}
                ></button>
                <button
                  className="listing-block-delete-link"
                  onClick={this.props.delete}
                ></button>
              </div>
            ) : null}
            <Link to={`/tournaments/${this.props.tournament.tournamentId}`}>
              <div className="thumb tournament-thumb">
                <img src={TThumb} alt="" />
              </div>
              <div className="listing-block-wrapper">
                <h3>
                  {this.props.tournament.name}
                  <span>
                    ({this.props.tournament.isVirtual ? "Virtual" : "Live"}{" "}
                    Tournament)
                  </span>
                </h3>
                {this.props.tournament.externalId !== null && (
                  <div className="ext-id">
                    Tournament Id: {this.props.tournament.externalId}
                  </div>
                )}
                {this.props.tournament.location !== null && (
                  <p>Location: {this.props.tournament.location}</p>
                )}
                <div className="ext-id"></div>
                <div className="tot-records">
                  <div className="tot-schools">
                    Total Schools: {this.props.tournament.totalSchools}
                  </div>
                  <div className="tot-students">
                    Total Archers: {this.props.tournament.totalStudents}
                  </div>
                </div>
                <div className="date">
                  Date:{" "}
                  {Functions.dateOnlyString(this.props.tournament.startDate)}
                </div>
                <div className="time">
                  Time:{" "}
                  {Functions.timeOnlyString(this.props.tournament.startDate)}
                </div>
                {this.props.tournamentState === "Upcoming" && (
                  <div className="status upcoming">
                    {this.props.tournamentState}
                  </div>
                )}
                {this.props.tournamentState === "In Progress" && (
                  <div className="status">{this.props.tournamentState}</div>
                )}
                {this.props.tournamentState === "Completed" && (
                  <div className="status complete">
                    {this.props.tournamentState}
                  </div>
                )}
              </div>
            </Link>
          </div>
        )}
      </AuthConsumer>
    );
  }
}
export default TournamentListItem;
