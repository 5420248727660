export const API_ENDPOINT_LOCAL = "http://localhost:8080";
export const API_ENDPOINT_DEV = "https://api-test.bulzi.app";
export const API_ENDPOINT_PROD = "https://api.bulzi.app";
export const API_ENDPOINT =
  process.env.REACT_APP_HOST_ENV === "local"
    ? API_ENDPOINT_LOCAL
    : process.env.REACT_APP_HOST_ENV === "test"
    ? API_ENDPOINT_DEV
    : API_ENDPOINT_PROD;

export const userRoles = {
  superadmin: "superadmin",
  coach: "coach",
  student: "student",
  spectator: "spectator",
};
