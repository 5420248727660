import React from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import PropTypes from 'prop-types';
import alertService from "../../services/alert";
const queryString = require('query-string');


class ResetPassword extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      }

  state = {
    password: null,
    passconfirm: null,
    wasSubmitted: false,
    redirectToLanding: false,
    errors: {
      password: "Password is required.",
      passconfirm: "Passwords must match"
    },
  };

  urlData = {};

  componentDidMount = () => {
    if (this.props.location.search) {
      this.urlData = queryString.parse(this.props.location.search);
    } else {
      this.props.history.replace("/");
    }
  };

  componentWillUnmount = () => {
    this.setState({
      redirectToLanding: false,
    });
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  updatePassword = async (user) => {
    await this.context.resetPassword(this.urlData.email, this.urlData.token, user.password);
    this.props.history.replace("/");
  };

  validate = () => {
    const { password, passconfirm, errors } = this.state;
    Object.entries(errors).forEach((el) => {
      const prop = el[0];
      switch (prop) {
        case "password":
          errors.password =
            password && password.trim().length > 0
              ? ""
              : "Password is required to proceed.";
          break;
          case "passconfirm":
          errors.passconfirm =
          passconfirm && passconfirm === password && passconfirm.trim().length > 0
              ? ""
              : "Confirm Password is required and must match.";
          break;
        default:
          break;
      }
    });
    const valid = this.validateForm(errors);
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { password, /*passconfirm,*/ errors } = this.state;
    this.setState({
      wasSubmitted: true,
    });
    if (this.validate(errors)) {
        const user = {password: password};
        this.updatePassword(user);
    } else {
      alertService.show({
        title: 'Error',
        message: 'Your form is invalid, please try agan.'
      });
    }
  };

  render() {
    const { errors } = this.state;
    // const { match, location, history } = this.props;

    return (
      <div className="forgot-password-content">
        <h2 className="fp-title">Reset Your Password</h2>
        <ul className="fp-list">
          <form onSubmit={this.handleSubmit} noValidate>
            <li className="fp-list-item">
              <label className="fp-label">New Password</label>
              <input
                name="password"
                type="password"
                placeholder="Enter password"
                onChange={this.handleChange}
                noValidate
              />
              {errors.password.length > 0 && this.state.wasSubmitted && (
                <div className="error">{errors.password}</div>
              )}
            </li>
            <li className="fp-list-item">
              <label className="fp-label">Confirm Password</label>
              <input
                name="passconfirm"
                type="password"
                placeholder="Confirm password"
                onChange={this.handleChange}
                noValidate
              />
              {errors.passconfirm.length > 0 && this.state.wasSubmitted && (
                <div className="error">{errors.passconfirm}</div>
              )}
            </li>
            <li>
              <button className="btn" onClick={(e) => this.handleSubmit(e)}>
                Change Password
              </button>
            </li>
          </form>
        </ul>
      </div>
    );
  }
}

ResetPassword.contextType = AuthContext;
export default withRouter(ResetPassword);
