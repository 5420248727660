import ApiService from "./ApiService";
import alertService from "../services/alert";

class TournamentService extends ApiService {
  static _instance;

  static async getInstance() {
    if (!TournamentService._instance) {
      TournamentService._instance = new TournamentService();
    }

    return TournamentService._instance;
  }

  async saveTournament(tournament) {
    const response = tournament.tournamentId
      ? await this.put(`/tournaments/${tournament.tournamentId}`, tournament)
      : await this.post("/tournaments", tournament);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async removeStudent(tournamentId, studentId) {
    const response = await this.delete(
      `/tournaments/${tournamentId}/students/${studentId}`
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async addStudents(tournamentId, studentIds) {
    const response = await this.post(`/tournaments/${tournamentId}/students`, {
      studentIds: studentIds,
    });

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async getSchools(tournamentId) {
    const response = await this.get(`/tournaments/${tournamentId}/schools`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async addSchools(tournamentId, schoolIds) {
    const response = await this.post(
      `/tournaments/${tournamentId}/schools`,
      schoolIds
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async removeSchool(tournamentId, schoolId) {
    const response = await this.delete(
      `/tournaments/${tournamentId}/schools/${schoolId}`
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async allTournaments() {
    const response = await this.getPages("/tournaments");

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async clearTournament(tournamentId) {
    const response = await this.delete(`/tournaments/${tournamentId}/schools`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async deleteTournament(tournamentId) {
    const response = await this.delete(`/tournaments/${tournamentId}`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async getStudentsForSchool(tournamentId, schoolId) {
    const response = await this.get(
      `/tournaments/${tournamentId}/schools/${schoolId}/students`
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async disableStudent(tournamentId, studentId, disableStatus) {
    const response = await this.put(
      `/tournaments/${tournamentId}/students/${studentId}?disqualified=${disableStatus}`
    );
    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async fetchSchoolDetailsInTournament(tournamentId, schoolId) {
    const response = await this.get(
      `/tournaments/${tournamentId}/schools/${schoolId}`
    );
    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async checkExternalIdInTournament(externalId) {
    const response = await this.get(`/tournaments?externalId=${externalId}`);
    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async logoutAlert() {
    const result = await alertService.show({
      title: "Authentication Error",
      message: 'Unauthorized user, log out'
    });
    if (result) {
      window.location.href = '/';
    }
  }
  
}

export default TournamentService;
