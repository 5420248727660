import React from "react";

class EmptyState extends React.Component {
  render() {
    return (
      <div className="no-records">
        <h3>{this.props.title}</h3>
        <p>{this.props.message}</p>
      </div>
    );
  }
}

export default EmptyState;
