import ApiService from "./ApiService";
import alertService from "../services/alert";

class SchoolService extends ApiService {
  static _instance;

  static async getInstance() {
    if (!SchoolService._instance) {
      SchoolService._instance = new SchoolService();
    }

    return SchoolService._instance;
  }

  async saveSchool(school) {
    const response = school.schoolId
      ? await this.put(`/schools/${school.schoolId}`, school)
      : await this.post("/schools", school);
    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async csvUpload(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    let token = sessionStorage.getItem("jwtToken");

    const response = await this.post('/data/schools', formData, {
      headers: {'Content-Type' : 'multipart/form-data', "Authorization": `Bearer ${token}`},
      params: {skipHeader: true, layout: 1},
      onUploadProgress
    });

    if (response.success) {
      return {success: true, fatal: false, data: response.data};
    } else if (response.fatal) {
      return {success: false, fatal: true};
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return {success: false, fatal: false, error: response.data.error};
    }
  }

  async allSchools(page = 0, size = 20) {
    const response = await this.get(
      `/schools?sort=name&page=${page}&size=${size}`
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async everySchool() {
    const response = await this.get(
      `/schools`
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async searchSchool(name) {
    const response = await this.get(
      `/schools?sort=name&page=0&size=20&name=${name}`
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async allSchoolsForTournament(tournamentId) {
    const response = await this.get(`/tournaments/${tournamentId}/schools`);
    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async getCoaches(schoolId) {
    const response = await this.get(`/schools/${schoolId}/coaches`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async getAllStudents(schoolId) {
    const response = await this.get(`/schools/${schoolId}/students`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  // async getStudentsForTournament(tournamentId, schoolId) {
  //   const response = await this.get(
  //     `/tournaments/${tournamentId}/schools/${schoolId}/students`
  //   );

  //   if (response.success) {
  //     return { success: true, fatal: false, data: response.data };
  //   } else if (response.fatal) {
  //     return { success: false, fatal: true };
  //   } else {
  //     return { success: false, fatal: false, error: response.data.error };
  //   }
  // }

  async getStudentsForTournament(tournamentId) {
    const response = await this.get(`/tournaments/${tournamentId}/students`);

    if (response.success) {
        return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
        return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
}

  async deleteSchool(schoolId) {
    const response = await this.delete(`/schools/${schoolId}`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async addCoachToSchool(schoolId, coach) {
    const response = await this.post(`/schools/${schoolId}/coaches`, coach);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async removeCoachFromSchool(schoolId, coachId) {
    const response = await this.delete(
      `/schools/${schoolId}/coaches/${coachId}`
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async getSchoolCoaches(schoolId) {
    const response = await this.get(`/schools/${schoolId}/coaches`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async getSchoolInfo(schoolId) {
    const response = await this.get(`/schools/${schoolId}`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async checkExternalIdInSchools(externalId) {
    const response = await this.get(`/schools?externalId=${externalId}`);
    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      if (response.data.error === 'Unauthorized') {
        this.logoutAlert();
      }
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async logoutAlert() {
    const result = await alertService.show({
      title: "Authentication Error",
      message: 'Unauthorized user, log out'
    });
    if (result) {
      window.location.href = '/';
    }
  }

}

export default SchoolService;
