import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { homeTabs } from "../../home";
import SlidingPane from "react-sliding-pane";
import CreateSchool from "../../home/schools/CreateSchool";
import CreateCoach from "../../home/coaches/CreateCoach";
import CreateStudent from "../../home/students/CreateStudent";
import "react-sliding-pane/dist/react-sliding-pane.css";
import CreateTournament from "../../home/tournaments/CreateTournament";
import { AuthConsumer } from "../../../context/AuthContext";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.closePanelRequested = this.closePanelRequested.bind(this);
    this.state = { openPanel: false };
  }

  openPanel() {
    this.setState({
      openPanel: true,
    });
  }

  closePanelRequested(refresh) {
    this.setState({
      openPanel: false,
    });

    if (refresh === true && this.props.refreshContent) {
      this.props.refreshContent();
    }
  }

  greetingText() {
    const now = moment();
    const currentHour = now.hour();
    if (currentHour >= 12 && currentHour <= 17) return "Good Afternoon";
    else if (currentHour >= 18) return "Good Evening";
    else return "Good Morning";
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="page-title">
            <AuthConsumer>
              {({ user }) => (
                <h2>
                  {this.greetingText()}, {user.name}.
                </h2>
              )}
            </AuthConsumer>
            <div className="page-title-add">
              {this.props.currentTab === homeTabs.TOURNAMENTS && (
                <button
                  id="create-tournament-btn"
                  className="btn"
                  onClick={() => this.openPanel()}
                >
                  + Create Tournament
                </button>
              )}
              {this.props.currentTab === homeTabs.SCHOOLS && (
                <button
                  id="add-school-btn"
                  className="btn"
                  onClick={() => this.openPanel()}
                >
                  + Add School
                </button>
              )}
              {this.props.currentTab === homeTabs.STUDENTS && (
                <button
                  id="add-student-btn"
                  className="btn"
                  onClick={() => this.openPanel()}
                >
                  + Add Archer
                </button>
              )}
              {this.props.currentTab === homeTabs.COACHES && (
                <button
                  id="add-coach-btn"
                  className="btn"
                  onClick={() => this.openPanel()}
                >
                  + Add Coach
                </button>
              )}

              <SlidingPane
                className="pane"
                isOpen={this.state.openPanel}
                width="480px"
                hideHeader={true}
                onRequestClose={() => {
                  this.setState({ openPanel: false });
                }}
              >
                {this.props.currentTab === homeTabs.TOURNAMENTS && (
                  <AuthConsumer>
                    {({ user }) => (
                      <CreateTournament
                        user={user}
                        edit={false}
                        onClosePanel={this.closePanelRequested}
                      />
                    )}
                  </AuthConsumer>
                )}
                {
                  this.props.currentTab === homeTabs.SCHOOLS && (
                    <CreateSchool
                      edit={false}
                      onClosePanel={this.closePanelRequested}
                    />
                  ) // This should be Add School component
                }
                {
                  this.props.currentTab === homeTabs.STUDENTS && (
                    <CreateStudent
                      csv={false}
                      edit={false}
                      onClosePanel={this.closePanelRequested}
                    />
                  ) // This should be Add Student component
                }
                {
                  this.props.currentTab === homeTabs.COACHES && (
                    <CreateCoach
                      edit={false}
                      onClosePanel={this.closePanelRequested}
                    />
                  ) // This should be Add Coach component
                }
              </SlidingPane>
            </div>
          </div>
          <div className="tabs">
            <ul>
              <li
                className={
                  this.props.currentTab === homeTabs.TOURNAMENTS ? "active" : ""
                }
              >
                <Link to="/tournaments">Tournaments</Link>
              </li>
              <li
                className={
                  this.props.currentTab === homeTabs.SCHOOLS ? "active" : ""
                }
              >
                <Link to="/schools">Schools</Link>
              </li>
              <li
                className={
                  this.props.currentTab === homeTabs.STUDENTS ? "active" : ""
                }
              >
                <Link to="/archers">Archers</Link>
              </li>
              <li
                className={
                  this.props.currentTab === homeTabs.COACHES ? "active" : ""
                }
              >
                <Link to="/coaches">Coaches</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Navigation;
