import React from "react";
import SchoolService from "../../../services/SchoolService";
import alertService from "../../../services/alert";
import CSVFileValidator from 'csv-file-validator';

class CreateSchool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      school: props.school ?? {
        name: null,
        externalId: null,
        sector: null,
        level: null,
        division: null,
      },
      nameError: false,
      idError: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.handleSectorChange = this.handleSectorChange.bind(this);
    this.handleLevelChange = this.handleLevelChange.bind(this);
    this.handleDivisionChange = this.handleDivisionChange.bind(this);
  }

  handleNameChange({ target: { value } }) {
    var { school } = this.state;
    school.name = value.trim().length === 0 ? null : value;

    this.setState({
      school: school,
    });
  }

  handleIdChange({ target: { value } }) {
    var { school } = this.state;
    school.externalId = value.trim().length === 0 ? null : value;
    this.setState({
      school: school,
    });
  }

  handleSectorChange({ target: { value } }) {
    var { school } = this.state;
    school.sector = value.trim().length === 0 ? null : value;

    this.setState({
      school: school,
    });
  }

  handleLevelChange({ target: { value } }) {
    var { school } = this.state;
    school.level = value.trim().length === 0 ? null : value;

    this.setState({
      school: school,
    });
  }

  handleDivisionChange({ target: { value } }) {
    var { school } = this.state;
    school.division = value.trim().length === 0 ? null : value;

    this.setState({
      school: school,
    });
  }

  async saveToServer() {
    const { school } = this.state;
    const service = await SchoolService.getInstance();
    const response = await service.saveSchool(school);

    if (response.success) {
      this.props.onClosePanel(true);
    } else {
      alertService.show({
        title: "Save Failed",
        message:
          "Unable to save school details at this time, please try again.",
      });
    }
  }

  validate() {
    var success = true;
    const { school } = this.state;

    if (!school.name || school.name.trim() === "") {
      this.setState({
        nameError: true,
      });
      success = false;
    } else {
      this.setState({
        nameError: false,
      });
    }

    return success;
  }

  submit() {
    if (this.validate()) {
      this.saveToServer();
    }
  }

  checkExternalIdExists = async () => {
    var { school } = this.state;
    if (school.externalId) {
      const service = await SchoolService.getInstance();
      const response = await service.checkExternalIdInSchools(
        school.externalId
      );
      if (response.success === true) {
        const { schoolId } = response.data;
        if (school.schoolId && school.schoolId === schoolId) {
          this.setState(
            {
              idError: false,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        } else if (school.schoolId && school.schoolId !== schoolId) {
          this.setState(
            {
              idError: true,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        } else {
          this.setState(
            {
              idError: true,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        }
      } else {
        this.setState(
          {
            idError: false,
          },
          () => {
            !this.state.idError && this.submit();
          }
        );
      }
    } else {
      this.submit();
    }
  };

  onReceiveFile = async(event) => {

    const CSVConfigValidation = {
      headers: [
        { name: 'school_id', inputName: 'school_id', required: true },
        { name: 'name', inputName: 'name', required: true },
        { name: 'coach', inputName: 'coach', required: true },
        { name: 'sector', inputName: 'sector', required: true },
        { name: 'level', inputName: 'level', required: true },
        { name: 'division', inputName: 'division', required: true },
        { name: 'tournament_id', inputName: 'tournament_id', required: true }
      ]
    };

    const fileEvent = event;
    const file = fileEvent.target.files[0];

    CSVFileValidator(file, CSVConfigValidation).then(csvData => {
      if (csvData.inValidMessages.length === 0) {
        this.uploadCSV(file);
      } else {
        alertService.show({
          title: "Invalid File Format",
          message: "CSV columns should be school_id, name, coach, sector, level, division, tournament_id.",
        });
        this.props.onClosePanel(true);
      }
    });
  }

  async uploadCSV(file, event) {
    const service = await SchoolService.getInstance();

    this.setState({
      progress: 0
    });

    service.csvUpload(file, (event) => {
      alertService.show({
        title: "Upload Pending",
        message: "Your CSV upload is in progress...",
        primary: 'Wait',
        isLoading: true
      });
    }).then((response) => {
      console.log(response);
      alertService.update({
        title: "Upload Complete",
        processed: `Processed: ${response.data.processed}`,
        updated: `Updated: ${response.data.updated}`,
        inserted: `Inserted: ${response.data.inserted}`,
        errors: `Errors: ${response.data.errors}`,
        errorLines: `errorLines: ${response.data.errorLines}`,
        isLoading: false
      });
      this.props.onClosePanel(true);
    }).catch((error) => {
      console.log(error);
      alertService.update({
        title: "Upload Failure",
        message: `A system error has occurred`,
        isLoading: false
      });
    });

  }

  // onReceiveFile = (file) => {
  //   const rows = file.data;
  //   const head = rows[0];
  //   if (head.includes("school_id")
  //       && head.includes("name")
  //       && head.includes("sector")
  //       && head.includes("level")
  //       && head.includes("division")) {
  //       rows.forEach(element => {
  //         const postBody = {
  //             externalId: element[0],
  //             name: element[1],
  //             sector: element[3],
  //             level: element[4],
  //             division: element[5]
  //         }
  //         console.log(postBody);
  //       });
  //   } else {
  //       console.log('invalid data received');
  //   }
  // }

  render() {
    const { school } = this.state;

    return (
      <section className="sidebar visible" id="add-schools">
        <div className="sidebar-wrapper">
          <h3>{this.props.edit === true ? "Edit" : "Add"} School</h3>
          <div className="sidebar-content-wrapper">
            <ul>
              <li>
                <label>School Name*</label>
                <input
                  type="text"
                  placeholder="Enter school name"
                  value={school.name ?? ""}
                  onChange={this.handleNameChange}
                />
                {this.state.nameError === true && (
                  <div className="error">Name required</div>
                )}
              </li>
              <li>
                <label>School ID</label>
                <input
                  type="text"
                  placeholder="Enter school id"
                  onChange={this.handleIdChange}
                  value={school.externalId ?? ""}
                />
                {this.state.idError ? (
                  <div className="error">ID already exists</div>
                ) : null}
              </li>
              <li>
                <label>Sector</label>
                <select
                  onChange={this.handleSectorChange}
                  value={school.sector ?? ""}
                >
                  <option value="">Select Sector</option>
                  <option value="AIMS">AIMS</option>
                  <option value="MHSAA">MHSAA</option>
                  <option value="MAIS">MAIS</option>
                  <option value="SESSA State Games">SESSA State Games</option>
                  <option value="AIMS XTREME">AIMS XTREME</option>
                </select>
              </li>
              <li>
                <label>Level</label>
                <select
                  onChange={this.handleLevelChange}
                  value={this.state.school.level ?? ""}
                >
                  <option value="">Select Level</option>
                  {/* <option value="AIMS">AIMS</option> */}
                  <option value="Elementary">Elementary</option>
                  <option value="Middle School">Middle School</option>
                  <option value="High School">High School</option>
                </select>
              </li>
              <li>
                <label>Division</label>
                <select
                  onChange={this.handleDivisionChange}
                  value={this.state.school.division ?? ""}>
                  <option value="">Select Division</option>
                  {/* <option value="AIMS">AIMS</option> */}
                  <option value="1A">1A</option>
                  <option value="2A">2A</option>
                  <option value="3A">3A</option>
                  <option value="4A">4A</option>
                  <option value="5A">5A</option>
                  <option value="6A">6A</option>
                  {/* <option value="SESSA Meridian">SESSA Meridian</option> */}
                </select>
              </li>
              {!this.props.edit && <li>
                  <label>Choose CSV to Upload</label>
                  <label className="custom-input">
                    Upload CSV 
                    <input type='file' accept=".csv" id="fileInput" onChange={this.onReceiveFile}/>
                  </label>
              </li>}
            </ul>
          </div>
          <div className="button-group">
            {/* {!this.props.edit && <button onClick={() => alert('upload')} className="btn btn-upload"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path></svg> Upload CSV</button>} */}
            <button
              onClick={() => this.props.onClosePanel()}
              className="btn btn-cancel"
            >
              Cancel
            </button>
            <button
              onClick={() => this.checkExternalIdExists()}
              className="btn"
            >
              Save
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default CreateSchool;
