import React from "react";
import * as Constants from "../../../util/constants";
import UserService from "../../../services/UserService";
import alertService from "../../../services/alert";
import CSVFileValidator from 'csv-file-validator';
import SchoolService from '../../../services/SchoolService';
import TournamentService from "../../../services/TournamentService";
// import Upload from '../../common/upload';

class CreateStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentError: false,
      schoolError: false,
      genderError: false,
      student: props.student ?? {
        role: Constants.userRoles.student,
        name: null,
        schoolId: null,
        email: null,
        gender: null,
        externalId: null,
      },
      schools: [],
      idError: false
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSchoolChange = this.handleSchoolChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
  }

  async fetchData() {
    const service = await SchoolService.getInstance();
    const response = await service.allSchools(0, 700);
    const { data } = response;
    if (response.success) {
      this.setState({
        isLoaded: true,
        schools: data.content,
      });
    } else {
      this.setState({
        isLoaded: true
      });
    }
  }

  // fetchData() {
  //   fetch(`${Constants.API_ENDPOINT}/schools`)
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         this.setState({
  //           isLoaded: true,
  //           schools: result.content,
  //         });
  //       },
  //       (error) => {
  //         this.setState({
  //           isLoaded: true,
  //           error,
  //         });
  //       }
  //     );
  // }

  componentDidMount() {
    this.fetchData();
    // This was being called every time the page loads, which is not what we want
  }

  async saveToServer() {
    const { student } = this.state;
    const service = await UserService.getInstance();
    const tourneyService = await TournamentService.getInstance();
    const response = await service.saveStudent(student);
    if (response.success) {
      if (this.props.isDirectAdd) {
        await tourneyService.addStudents(this.props.tournamentId, [response.data.userId]);
        this.props.onClosePanel(true);
      } else {
        this.props.onClosePanel(true);
      }
    } else {
      alertService.show({
        title: "Save Failed",
        message: "Unable to save user details at this time, please try again.",
      });
    }
  }

  validate() {
    var success = true;
    if (!this.state.student.name || this.state.student.name.trim() === "") {
      this.setState({
        studentError: true,
      });
      success = false;
    } else {
      this.setState({
        studentError: false,
      });
    }
    if (
      !this.state.student.schoolId ||
      this.state.student.schoolId.toString().trim() === ""
    ) {
      this.setState({
        schoolError: true,
      });
      success = false;
    } else {
      this.setState({
        schoolError: false,
      });
    }
    if (!this.state.student.gender || this.state.student.gender.trim() === "") {
      this.setState({
        genderError: true,
      });
      success = false;
    } else {
      this.setState({
        genderError: false,
      });
    }

    return success;
  }

  submit() {
    if (this.validate()) {
      this.saveToServer();
      this.props.onClosePanel();
    }
  }

  checkExternalIdExists = async () => {
    var { student } = this.state;
    if (student.externalId) {
      const service = await UserService.getInstance();
      const response = await service.checkExternalIdInUsers(student.externalId);
      if (response.success === true) {
        const { userId } = response.data;
        if (student.userId && student.userId === userId) {
          this.setState(
            {
              idError: false,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        } else if (student.userId && student.userId !== userId) {
          this.setState(
            {
              idError: true,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        } else {
          this.setState(
            {
              idError: true,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        }
      } else {
        this.setState(
          {
            idError: false,
          },
          () => {
            !this.state.idError && this.submit();
          }
        );
      }
    } else {
      this.submit();
    }
  };

  handleSchoolChange({ target: { value } }) {
    var { student } = this.state;
    student.schoolId = value.trim().length === 0 ? null : value;

    this.setState({
      student: student,
    });
  }

  handleNameChange({ target: { value } }) {
    var { student } = this.state;
    student.name = value.trim().length === 0 ? null : value;

    this.setState({
      student: student,
    });
  }

  handleIdChange({ target: { value } }) {
    var student = this.state.student;
    student.externalId = value.trim().length === 0 ? null : value;
    this.setState({
      student: student,
    });
  }

  handleEmailChange({ target: { value } }) {
    var { student } = this.state;
    student.email = value.trim().length === 0 ? null : value;

    this.setState({
      student: student,
    });
  }

  handleGenderChange({ target: { value } }) {
    var { student } = this.state;
    student.gender = value.trim().length === 0 ? null : value;

    this.setState({
      student: student,
    });
  }

  onReceiveFile = async(event) => {

    // old config 
    // const CSVConfigValidation = {
    //   headers: [
    //     { name: 'school_id', inputName: 'school_id', required: false },
    //     { name: 'school_name', inputName: 'school_name', required: false },
    //     { name: 'shooter_id', inputName: 'shooter_id', required: true },
    //     { name: 'name', inputName: 'name', required: true },
    //     { name: 'gender', inputName: 'gender', required: true },
    //     { name: 'tournament_id', inputName: 'tournament_id', required: false },
    //     { name: 'grades', inputName: 'grades', required: false },
    //   ]
    // };

    // new 4 column config
    const CSVConfigValidation = {
      headers: [
        { name: 'Shooter_ID', inputName: 'Shooter_ID', required: true },
        { name: 'Name', inputName: 'Name', required: true },
        { name: 'Gender', inputName: 'Gender', required: true },
        { name: 'Grades', inputName: 'Grades', required: false },
      ]
    };

    const fileEvent = event;
    const file = fileEvent.target.files[0];

    CSVFileValidator(file, CSVConfigValidation).then(csvData => {

      if (csvData.inValidMessages.length === 0) {
        // this.uploadCSV(file, event);
        this.uploadCSVFourColumn(file, event);
      } else {
        csvData.inValidMessages.forEach((message) => {
          console.log(message);
        });
        alertService.show({
          title: "Invalid File Format",
          message: "Please check that your column headers are correct.",
        });
        this.props.onClosePanel(true);
      }
    });
  }

  async uploadCSV(file, event) {
    const service = await UserService.getInstance();

    this.setState({
      progress: 0
    });

    service.csvUpload(file, (event) => {
      alertService.show({
        title: "Upload Pending",
        message: "Your CSV upload is in progress...",
        primary: 'Wait',
        isLoading: true
      });
    }).then((response) => {
      console.log(response);
      alertService.update({
        title: "Upload Complete",
        processed: `Processed: ${response.data.processed}`,
        updated: `Updated: ${response.data.updated}`,
        inserted: `Inserted: ${response.data.inserted}`,
        errors: `Errors: ${response.data.errors}`,
        errorLines: `errorLines: ${response.data.errorLines}`,
        isLoading: false
      });
      this.props.onClosePanel(true);
    }).catch((error) => {
      console.log(error);
      alertService.update({
        title: "Upload Failure",
        message: `A system error has occurred`,
        isLoading: false
      });
    });

  }

  async uploadCSVFourColumn(file, event) {
    const service = await UserService.getInstance();

    this.setState({
      progress: 0
    });

    service.csvUploadFourColumn(file, (event) => {
      alertService.show({
        title: "Upload Pending",
        message: "Your CSV upload is in progress...",
        primary: 'Wait',
        isLoading: true
      });
    }).then((response) => {
      alertService.update({
        title: "Upload Complete",
        processed: `Processed: ${response.data.processed}`,
        updated: `Updated: ${response.data.updated}`,
        inserted: `Inserted: ${response.data.inserted}`,
        errors: `Errors: ${response.data.errors}`,
        errorLines: `errorLines: ${response.data.errorLines}`,
        isLoading: false
      });
      this.props.onClosePanel(true);
    }).catch((error) => {
      alertService.update({
        title: "Upload Failure",
        message: `A system error has occurred`,
        isLoading: false
      });
    });

  }

  // onReceiveFile = (file) => {
  //     //parse file
  //     const rows = file.data;
  //     const head = rows[0]
  //     console.log('rows', file.data[0])
  //     if (head.includes("school_id")
  //         && head.includes("name")
  //         && head.includes("sector")
  //         && head.includes("level")
  //         && head.includes("division")) {
  //         rows.forEach(element => {
  //             const postBody = {
  //                 externalId: element[0],
  //                 name: element[1],
  //                 sector: element[3],
  //                 level: element[4],
  //                 division: element[5]
  //             }
  //             console.log(postBody)
  //             // call service with this object
  //         });
  //     } else {
  //         console.log('invalid data received')
  //     }
  // }

  render() {
    return (
      <section className="sidebar visible" id="add-students">
        <div className="sidebar-wrapper">
          <h3>{this.props.edit === true ? "Edit" : "Add"} Archers</h3>
          <div className="sidebar-content-wrapper">
            <ul>
              <li>
                <label>Archer Name*</label>
                <input
                  type="text"
                  value={this.state.student.name ?? ""}
                  onChange={this.handleNameChange}
                  placeholder="Enter archer name"
                />
                {this.state.studentError === true && (
                  <div className="error">Archer required</div>
                )}
              </li>
              <li>
                <label>Archer Email</label>
                <input
                  type="text"
                  placeholder="Enter archer email"
                  value={this.state.student.email ?? ""}
                  onChange={this.handleEmailChange}
                />
              </li>
              <li>
                <label>School Name*</label>
                <select
                  onChange={this.handleSchoolChange}
                  value={this.state.student.schoolId ?? ""}
                >
                  <option value="default">Select School</option>
                  {this.state.schools.map((item) => (
                    <option value={item.schoolId} key={item.schoolId}>
                      {item.name} {item.level ? '(' + item.level + ')' : ''}
                    </option>
                  ))}
                </select>
                {this.state.schoolError === true && (
                  <div className="error">School required</div>
                )}
              </li>
              <li>
                <label>Archer ID</label>
                <input
                  type="text"
                  placeholder="Enter archer id"
                  value={this.state.student.externalId ?? ""}
                  onChange={this.handleIdChange}
                />
                {this.state.idError ? (
                  <div className="error">ID already exists</div>
                ) : null}
              </li>
              <li>
                <label>Gender*</label>
                <select
                  value={this.state.student.gender ?? ""}
                  onChange={this.handleGenderChange}
                >
                  <option value="">Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
                {this.state.genderError === true && (
                  <div className="error">Gender required</div>
                )}
              </li>
              {!this.props.edit && !this.props.isDirectAdd && <li>
                  <label>Choose CSV to Upload</label>
                  <label className="custom-input">
                    Upload CSV 
                    <input type='file' accept=".csv" id="fileInput" onChange={this.onReceiveFile}/>
                  </label>
              </li>}
              {/* {!this.props.edit && <li>
                                <label>Upload CSV</label>
                                <div className="inputfile-box">
                                    <p>Drop your files to upload or</p>
                                    <Upload onReceiveFile={this.onReceiveFile} />
                                </div>
                            </li>} */}
            </ul>
          </div>
          <div className="button-group">
            <button
              onClick={() => this.props.onClosePanel()}
              className="btn btn-cancel"
            >
              Cancel
            </button>
            <button
              onClick={() => this.checkExternalIdExists()}
              className="btn"
            >
              Save
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default CreateStudent;
