import React from "react";
import { Route } from "react-router-dom";
import moment from "moment";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import * as Functions from "../../util/helpers";
import * as Constants from "../../util/constants";
import CreateStudent from "../home/students/CreateStudent";
import BackArrow from "../../assets/images/back-arrow.svg";
import TThumb from "../../assets/images/tournaments-thumb.png";
import Header from "../common/header";
import AddParticipants from "./AddParticipants";
import TournamentSchools from "./TournamentSchools";
import TournamentSchoolStudents from "./TournamentSchoolStudents";
import { TournamentStudents } from './TournamentStudents';
import { AuthConsumer } from "../../context/AuthContext";
import { userRoles } from "../../util/constants";

class Tournament extends React.Component {
  constructor(props) {
    super(props);

    this.closePanelRequested = this.closePanelRequested.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      openPanel: false,
      openPanelArcher: false,
      error: null,
      isLoaded: false,
      tournament: null,
      reRender: false,
      isBulzi: null,
    };
  }

  fetchData() {
    const {
      match: { params },
    } = this.props;
    const tournamentId = params.tournamentId;

    let token = sessionStorage.getItem("jwtToken");

    let config = {
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
    };

    fetch(`${Constants.API_ENDPOINT}/tournaments/${tournamentId}`, config)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            tournamentId: params.tournamentId,
            isLoaded: true,
            tournament: result,
            isBulzi: result.isBulzi
          });
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    this.fetchData();
  }

  openPanel() {
    this.setState({
      openPanel: true,
    });
  }

  openArcherPanel() {
    this.setState({
      openPanelArcher: true,
    });
  }

  closePanelRequested() {
    const { reRender } = this.state;
    this.setState({
      openPanel: false,
      openPanelArcher: false,
      reRender: !reRender,
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  fetchTournamentState = () => {
    const { tournament } = this.state;
    const now = moment();
    if (
      tournament.endDate &&
      now.isAfter(moment(tournament.startDate))
      && now.isBefore(moment(tournament.endDate))) {
      return "In Progress";
    } else if (moment(tournament.startDate).isAfter(now) || moment(tournament.startDate).isSame(now)
    ) {
      return "Upcoming";
    } else {
      return "Completed";
    }
  };

  render() {
    const { tournament, error, isLoaded, reRender, isBulzi } = this.state;
    if (error) {
      return null;
    } else if (!isLoaded) {
      return null;
    } else {
      const tournamentStatus = this.fetchTournamentState();
      return (
        <div>
          <Header />
          <main>
            <div className="container">
              <div className="page-title">
                <div className="back" onClick={this.goBack}>
                  <img src={BackArrow} alt="" />
                  <span>Back</span>
                </div>
                <div className="page-title-add">
                  <button
                    className="btn visible"
                    style={{ marginRight: '20px' }}
                    onClick={() => this.openPanel()}
                  >
                    + Add Participants
                  </button>
                  <AuthConsumer>
                    {({ user }) => (
                      <span>
                        {isBulzi && tournamentStatus === "In Progress" && (user.role === userRoles.superadmin || user.userId === tournament.ownerId) && (
                          <button
                            className="btn visible"
                            onClick={() => this.openArcherPanel()}
                          >
                            + Add Archer to Tournament
                          </button>
                        )}
                      </span>
                    )}
                  </AuthConsumer>
                </div>
              </div>
              <SlidingPane
                className="pane"
                isOpen={this.state.openPanel}
                width="480px"
                hideHeader={true}
                onRequestClose={() => {
                  this.setState({ openPanel: false });
                }}
              >
                <AddParticipants
                  tournamentId={this.state.tournament.tournamentId}
                  onClosePanel={this.closePanelRequested}
                />
              </SlidingPane>
              <SlidingPane
                className="pane"
                isOpen={this.state.openPanelArcher}
                width="480px"
                hideHeader={true}
                onRequestClose={() => {
                  this.setState({ openPanelArcher: false });
                }}
              >
                <CreateStudent
                  tournamentId={this.state.tournament.tournamentId}
                  isDirectAdd={true}
                  onClosePanel={this.closePanelRequested}
                />
              </SlidingPane>
              <div className="listing-block details-listing-block">
                <button className="listing-block-more"></button>
                <div className="thumb tournament-thumb">
                  <img src={TThumb} alt="" />
                </div>
                <div className="listing-block-wrapper">
                  <h3>
                    {tournament.name}
                    <span>
                      ({tournament.isVirtual ? "Virtual" : "Live"} Tournament)
                    </span>
                  </h3>
                  {tournament.location !== null && (
                    <p>Location: {tournament.location}</p>
                  )}
                  {tournament.externalId !== null && (
                    <div className="ext-id">
                      Tournament Id: {tournament.tournamentId}
                    </div>
                  )}
                  <div className="tot-records">
                    <div className="tot-schools">
                      Total Schools: {tournament.totalSchools}
                    </div>
                    <div className="tot-students">
                      Total Archers: {tournament.totalStudents}
                    </div>
                  </div>
                  <div className="date">
                    Date: {Functions.dateOnlyString(tournament.startDate)}
                  </div>
                  <div className="time">
                    {Functions.timeOnlyString(tournament.startDate)}
                  </div>
                  {tournamentStatus === "Upcoming" && (
                    <div className="status upcoming">{tournamentStatus}</div>
                  )}
                  {tournamentStatus === "In Progress" && (
                    <div className="status">{tournamentStatus}</div>
                  )}
                  {tournamentStatus === "Completed" && (
                    <div className="status complete">{tournamentStatus}</div>
                  )}
                </div>
              </div>
              {isBulzi &&
                <Route exact path="/tournaments/:tournamentId" component={(props) => <TournamentStudents {...props} tournament={tournament} tournamentStatus={tournamentStatus} reRender={reRender} />} />
              }
              {!isBulzi &&
                <Route
                  exact
                  path="/tournaments/:tournamentId"
                  component={(props) => (
                    <TournamentSchools
                      {...props}
                      tournament={tournament}
                      tournamentStatus={tournamentStatus}
                      reRender={reRender}
                    />
                  )}
                />
              }
              {!isBulzi &&
                <Route
                  path="/tournaments/:tournamentId/:schoolId"
                  component={(props) => (
                    <TournamentSchoolStudents
                      tournament={tournament}
                      tournamentStatus={tournamentStatus}
                      {...props}
                      reRender={reRender}
                    />
                  )}
                />
              }
            </div>
          </main>
        </div>
      );
    }
  }
}

export default Tournament;

