import React from "react";
import { Link } from "react-router-dom";
import { AuthConsumer } from "../../../context/AuthContext";
import { userRoles } from "../../../util/constants";

class SchoolListItem extends React.Component {
  render() {
    return (
      <AuthConsumer>
        {({ user }) => (
          <div className="row">
            <Link
              to={`/schools/${this.props.school.schoolId}`}
              className="cols w-40"
            >
              <div>{this.props.school.name}</div>
            </Link>
            <div className="cols w-15">
              {this.props.school.externalId === null
                ? "-"
                : this.props.school.externalId}
            </div>
            <div className="cols w-15">
              {this.props.school.sector === null
                ? "-"
                : this.props.school.sector}
            </div>
            <div className="cols w-15">
              {this.props.school.level === null ? "-" : this.props.school.level}
            </div>
            <div className="cols w-15">
              {this.props.school.division === null
                ? "-"
                : this.props.school.division}
            </div>
            <div className="cols w-100p">
              {user.schoolId === this.props.school.schoolId ||
              user.role === userRoles.superadmin ? (
                <div className="table-view-more">
                  <button
                    className="link table-view-edit-link"
                    onClick={this.props.edit}
                  ></button>
                  <button
                    className="link table-view-delete-link"
                    onClick={this.props.delete}
                  ></button>
                </div>
              ) : (
                <div className="table-view-more">&nbsp;</div>
              )}
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}
export default SchoolListItem;
