import moment from "moment";

// https://momentjs.com/docs/

export const dateToAPIFormat = (date) => {
  return moment(date).toISOString();
};

export const dateOnlyString = (date) => {
  moment.locale("en-US");
  return moment(date).format("dddd, MMM Do YYYY");
};

export const timeOnlyString = (date) => {
  moment.locale("en-US");
  return moment(date).format("h:mmA");
};
