import React from "react";
import CoachListItem from "./CoachListItem";
import EmptyState from "../../common/emptyState/EmptyState";
import Loading from "../../common/loading/Loading";
import ErrorState from "../../common/errorState/ErrorState";
import alertService from "../../../services/alert";
import UserService from "../../../services/UserService";
import CreateCoach from "./CreateCoach";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { userRoles } from "../../../util/constants";

class Coaches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isNext: false,
      isPrevious: false,
      totalPages: 0,
      page: 0,
      delete: null,
      edit: {},
      totalCoaches: 0,
      coaches: [],
      showAlert: false,
      openPanel: false,
    };

    this.closePanelRequested = this.closePanelRequested.bind(this);
  }

  componentDidUpdate(props) {
    const { refresh } = this.props;
    if (props.refresh !== refresh) {
      this.fetchData(this.state.page);
    }
  }

  editClicked(coach) {
    this.setState({
      edit: coach,
      openPanel: true,
    });
  }

  closePanelRequested() {
    this.setState({
      openPanel: false,
      edit: {},
    });

    this.fetchData(this.state.page);
  }

  async deleteClicked(coach) {
    const result = await alertService.show({
      title: "Confirm Delete",
      message: `Deleting a coach is permanent, are you sure you want to delete ${coach.name}?`,
      primary: "Delete",
      secondary: "Cancel",
    });

    if (result) {
      this.deleteFromServer(coach);
    }
  }

  async deleteFromServer({ userId }) {
    const service = await UserService.getInstance();
    const response = await service.deleteUser(userId);

    if (response.success) {
      this.fetchData(this.state.page);
    } else {
      alertService.show({
        title: "Delete Failed",
        message: "Unable to delete coach at this time, please try again.",
      });
    }
  }

  async fetchData(pageNum = 0) {
    const service = await UserService.getInstance();
    const response = await service.allUsers(userRoles.coach, pageNum);
    const { data } = response;

    if (response.success) {
      this.setState({
        isLoaded: true,
        coaches: data.content,
        page: data.number,
        isNext: data.last !== true,
        isPrevious: data.first !== true,
        totalPages: data.totalPages,
        totalCoaches: data.totalElements,
        delete: null,
        openPanel: false,
      });
    } else {
      this.setState({
        isLoaded: true,
        error: true,
      });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { error, isLoaded, coaches } = this.state;
    if (error) {
      return (
        <ErrorState title="An error has occurred loading this content, please try again" />
      );
    } else if (!isLoaded) {
      return <Loading title="Loading..." />;
    } else {
      return (
        <div className="tab-content active-tab-content" id="coaches">
          <div className="section-title">
            <h5>{this.state.totalCoaches} Coaches</h5>
            <div className="pager-nav">
              {this.state.isPrevious === true && (
                <button
                  className="pager-nav-prev"
                  onClick={() => this.fetchData(this.state.page - 1)}
                >
                  Previous
                </button>
              )}
              Page {this.state.page + 1} of {this.state.totalPages}
              {this.state.isNext === true && (
                <button
                  className="pager-nav-next"
                  onClick={() => this.fetchData(this.state.page + 1)}
                >
                  Next
                </button>
              )}
            </div>
          </div>
          <div className="table-view">
            <div className="row head-row">
              <div className="cols w-35">Coach Name</div>
              <div className="cols w-35">Schools</div>
              <div className="cols w-30">Email</div>
              <div className="cols w-100p">&nbsp;</div>
            </div>
            {coaches.map((item) => (
              <CoachListItem
                coach={item}
                key={item.userId}
                edit={() => this.editClicked(item)}
                delete={() => this.deleteClicked(item)}
              />
            ))}
            {coaches.length === 0 && (
              <EmptyState
                title="There are no coaches"
                message="There are currently no coaches. Come back later"
              />
            )}
          </div>
          <SlidingPane
            className="pane"
            isOpen={this.state.openPanel}
            width="480px"
            hideHeader={true}
            // closeIcon={<div />}
            onRequestClose={() => {
              this.setState({ openPanel: false });
            }}
          >
            <CreateCoach
              edit={true}
              coach={this.state.edit}
              onClosePanel={this.closePanelRequested}
            />
          </SlidingPane>
        </div>
      );
    }
  }
}

export default Coaches;
