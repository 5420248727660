import React from "react";
import Loading from "../common/loading/Loading";
import ErrorState from "../common/errorState/ErrorState";
import SchoolService from "../../services/SchoolService";
import alertService from "../../services/alert";
import TournamentService from "../../services/TournamentService";
// import Upload from '../common/upload';

class AddParticipants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      schoolsLoaded: false,
      selectedSchoolsLoaded: false,
      schools: [],
      allSchoolIds: [],
      selectedSchoolIds: [],
      activeSchools: [],
      selectAll: false,
      adding: false,
      term: "",
      searchedSchools: [],
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    const { schools } = this.state;
    const search = e.target.value;
    this.setState({ term: e.target.value });
    let foundSchools = [];
    foundSchools = schools.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({
      searchedSchools: foundSchools.length ? foundSchools : schools,
    });
  };

  async fetchSchools() {
    const service = await SchoolService.getInstance();
    // TODO: Need to likely support pagination
    const response = await service.allSchools(0, 700);
    const { data } = response;
    if (response.success) {
      const schoolIds = data.content.map((item) => item.schoolId);
      this.setState({
        error: false,
        schoolsLoaded: true,
        schools: data.content,
        searchedSchools: data.content,
        allSchoolIds: schoolIds,
      });
    } else {
      this.setState({
        error: true,
        schoolsLoaded: true,
      });
    }
  }

  async fetchActiveSchools() {
    const service = await TournamentService.getInstance();
    const response = await service.getSchools(this.props.tournamentId);
    const { data } = response;
    if (response.success) {
      let selectedSchools = [];
      Object.keys(data).forEach((school) => {
        data[school].forEach((item) => {
          selectedSchools.push(item.schoolId);
        });
      });
      this.setState({
        selectedSchoolIds: selectedSchools,
        selectedSchoolsLoaded: true,
        selectAll:
          this.state.allSchoolIds.length === selectedSchools.length
            ? true
            : false,
      });
    }
  }

  async saveToServer() {
    this.setState({ adding: true });
    const { selectedSchoolIds } = this.state;
    const service = await TournamentService.getInstance();
    await service.clearTournament(this.props.tournamentId);
    const response = await service.addSchools(this.props.tournamentId, {
      schoolIds: selectedSchoolIds,
    });

    if (response.success) {
      this.fetchActiveSchools();
      this.props.onClosePanel(true);
    } else {
      alertService.show({
        title: "Save Failed",
        message:
          "Unable to save add particpants at this time, please try again.",
      });
    }
    this.setState({ adding: false });
  }

  componentDidMount() {
    this.fetchSchools();
    this.fetchActiveSchools();
  }

  toggleSchool({ schoolId }) {
    const { selectedSchoolIds } = this.state;
    let ids = selectedSchoolIds;
    const index = selectedSchoolIds.indexOf(schoolId);
    if (index > -1) {
      ids.splice(index, 1);
      this.setState({ selectedSchoolIds: ids });
    } else {
      ids.push(schoolId);
      this.setState({ selectedSchoolIds: ids });
    }
  }

  selectAllSchools = () => {
    const { allSchoolIds, selectAll } = this.state;
    if (selectAll) {
      this.setState({ selectedSchoolIds: [], selectAll: false });
    } else {
      this.setState({ selectedSchoolIds: allSchoolIds, selectAll: true });
    }
  };

  // onReceiveFile = (file) => {
  // 	console.log('data received', file)
  // }

  render() {
    const {
      error,
      searchedSchools,
      selectedSchoolIds,
      schoolsLoaded,
      selectedSchoolsLoaded,
      adding,
    } = this.state;
    if (error) {
      return (
        <ErrorState title="An error has occurred loading this content, please try again" />
      );
    } else if (!schoolsLoaded || !selectedSchoolsLoaded) {
      return <Loading title="Loading..." />;
    } else {
      return (
        <section className="sidebar visible" id="add-participants">
          <div className="sidebar-wrapper">
            <h3>Add Participants</h3>
            <div className="sidebar-content-wrapper">
              {/* <p>Upload a CSV of the schools in the tournament or add them from the list below.</p>
							<ul>
								<li>
									<label>Upload CSV</label>
									<div className="inputfile-box">
										<p>Drop your files to upload or</p>
										<Upload onReceiveFile={this.onReceiveFile} />
									</div>
								</li>
							</ul> */}
              <input
                type="text"
                placeholder="Search School"
                value={this.state.term}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="table-view">
                <div className="row head-row">
                  <div className="cols w-100">Select All</div>
                  <div className="cols w-50p">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={this.state.selectAll}
                        onChange={() => this.selectAllSchools()}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                {searchedSchools.map((item) => (
                  <div className="row" key={item.schoolId}>
                    <div className="cols w-100">
                      {item.name}
                      <div className="smaller">
                        {item.sector} {item.level} {item.division}
                      </div>
                    </div>
                    <div className="cols w-50p">
                      <label className="custom-checkbox custom-checkbox-circle">
                        <input
                          type="checkbox"
                          checked={selectedSchoolIds.includes(item.schoolId)}
                          onChange={() => this.toggleSchool(item)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="button-group">
              <button
                onClick={() => this.props.onClosePanel()}
                className="btn btn-cancel"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  !adding && this.saveToServer();
                }}
                className="btn"
              >
                {adding ? "Saving.." : "Save"}
              </button>
            </div>
          </div>
        </section>
      );
    }
  }
}
export default AddParticipants;
