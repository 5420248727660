import React from "react";

class ErrorState extends React.Component {
  render() {
    return (
      <div className="error-alerts">
        <h3>{this.props.title}</h3>
      </div>
    );
  }
}

export default ErrorState;
