// import BaseService from './base-service';
import ApiService from "./ApiService";
import alertService from "../services/alert";

class UserService extends ApiService {
  static _instance;

  static async getInstance() {
    if (!UserService._instance) {
      UserService._instance = new UserService();
    }

    return UserService._instance;
  }

  async findByEmail(email) {
    const response = await this.get(`/users?email=${email}`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async saveUser(user) {
    const response = user.userId
      ? await this.put(`/users/${user.userId}`, user)
      : await this.post("/users", user);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      alertService.show({
        title: "Save User Failed",
        message: response.data.message,
      });
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async saveStudent(user) {
    const student = {...user, role: 'student'};
    const response = user.userId
      ? await this.put(`/users/${student.userId}`, student)
      : await this.post("/users", student);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      alertService.show({
        title: "Save User Failed",
        message: response.data.message,
      });
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  // standard / old student upload
  async csvUpload(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    let token = sessionStorage.getItem("jwtToken");

    const response = await this.post('/data/students', formData, {
      headers: {'Content-Type' : 'multipart/form-data', "Authorization": `Bearer ${token}`},
      params: {skipHeader: true, layout: 1},
      onUploadProgress
    });

    if (response.success) {
      return {success: true, fatal: false, data: response.data};
    } else if (response.fatal) {
      return {success: false, fatal: true};
    } else {
      return {success: false, fatal: false, error: response.data.error};
    }
  }

  // upload students 4 column format
  async csvUploadFourColumn(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    let token = sessionStorage.getItem("jwtToken");

    const response = await this.post('/data/students', formData, {
      headers: {'Content-Type' : 'multipart/form-data', "Authorization": `Bearer ${token}`},
      params: {skipHeader: true, layout: 2},
      onUploadProgress
    });

    if (response.success) {
      return {success: true, fatal: false, data: response.data};
    } else if (response.fatal) {
      return {success: false, fatal: true};
    } else {
      return {success: false, fatal: false, error: response.data.error};
    }
  }

  async allUsers(role, page = 0, size = 20) {
    const response = await this.get(
      `/users?sort=name&page=${page}&size=${size}&role=${role}`
    );

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async deleteUser(userId) {
    const response = await this.delete(`/users/${userId}`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async signIn(user) {
    const response = await this.loginPost("/users/login", user);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: 'sign in error' };
    }
  }

  async forgotPassword(email) {
    const response = await this.post("/users/forgot", {email});

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async resetPassword(email, token, password) {

    const response = await this.post("/users/reset", {
      email,
      token,
      password
    });

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async getSchoolsForUser(userId) {
    const response = await this.get(`/users/${userId}/schools`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: response.data.error };
    }
  }

  async checkExternalIdInUsers(externalId) {
    const response = await this.get(`/users?externalId=${externalId}`);
    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: response.data.error };
    }
  }
}

export default UserService;
