import React, { useEffect, useState } from "react";

import { TournamentResults } from "./TournamentResults";
import ErrorState from "../common/errorState/ErrorState";
import Loading from "../common/loading/Loading";
import SchoolService from "../../services/SchoolService";

export const TournamentStudents = (props) => {
  const tournamentId = props.match.params.tournamentId;

  // const [grades, setGrades] = useState([]);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchStudents(tournamentId);
  }, [tournamentId]);

  const fetchStudents = async (tournamentId) => {
    const service = await SchoolService.getInstance();
    const response = await service.getStudentsForTournament(tournamentId);
    if (response.success) {
      // Create an array of unique values containing all possible grades.
      // const gradesData = [...new Set(response.data.map((student) => student.grades))];
      // const tempGrades = [];
      // gradesData.forEach((grade) => {
      //   if (grade !== null && grade !== '') {
      //     tempGrades.push(grade);
      //   }
      // });
      // setGrades(tempGrades.length > 0 ? tempGrades : undefined);
      // setGrades([...new Set(response.data.map((student) => student.grades))]);
      setData(response.data);
    } else {
      setError(true);
    }
  };

  if (data) {
    return (
      <div className="tabs-wrapper">
        <TournamentResults
          data={data}
        />
        {/* {grades ? (
          <>
            {grades.map((value, index) => (
              <TournamentResults
                key={index}
                grades={value}
                data={data.filter((student) => student.grades === value)}
              />
            ))}
          </>
        ) : (
          <TournamentResults
            data={data}
          />
        )} */}
      </div>
    );
  } else if (error) {
    return (
      <div className="tabs-wrapper">
        <ErrorState title="An error has occurred while loading this content. Please try again." />
      </div>
    );
  } else {
    return (
      <div className="tabs-wrapper">
        <Loading title="Loading..." />
      </div>
    );
  }
};
