import React from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import alertService from "../../services/alert";


const validEmailRegex = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

class ForgotPassword extends React.Component {
  state = {
    email: null,
    wasSubmitted: false,
    errors: {
      email: "Must be a valid email format.",
    },
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Must be a valid email format.";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value.trim() });
  };

  handleSubmit = async (event) => {
    this.setState({
      wasSubmitted: true,
    });
    event.preventDefault();
    if (this.validateForm(this.state.errors)) {
      const { email } = this.state;
      await this.context.forgotPassword(email);
      this.props.history.replace("/");
    } else {
      alertService.show({
        title: 'Error',
        message: 'Your form is invalid, please try agan.'
      });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="forgot-password-content">
        <h2 className="fp-title">Request Password Reset</h2>
        <ul className="fp-list">
          <form onSubmit={this.handleSubmit} noValidate>
            <li className="fp-list-item">
              <label className="fp-label">Email</label>
              <input
                name="email"
                type="text"
                placeholder="Enter email address"
                onChange={this.handleChange}
                noValidate
              />
              {errors.email.length > 0 && this.state.wasSubmitted && (
                <div className="error">{errors.email}</div>
              )}
            </li>
            <li>
              <button className="btn">Submit</button>
            </li>
          </form>
        </ul>
      </div>
    );
  }
}
ForgotPassword.contextType = AuthContext;
export default withRouter(ForgotPassword);
