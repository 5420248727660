import React from "react";

import Home from "./components/home";
import Landing from "./components/landing";
import Tournament from "./components/tournament";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import School from "./components/school/School";
import ForgotPassword from "./components/landing/ForgotPassword";
import ResetPassword from "./components/landing/ResetPassword";


function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <ProtectedRoute path="/schools/:schoolId" component={School} />
          <ProtectedRoute
            path="/tournaments/:tournamentId"
            component={Tournament}
          />
          <ProtectedRoute path="/:section" component={Home} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
