import React from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import BackArrow from "../../assets/images/back-arrow.svg";
import TThumb from "../../assets/images/school-thumb.png";
import alertService from "../../services/alert";
import SchoolService from "../../services/SchoolService";
import UserService from "../../services/UserService";
import ErrorState from "../common/errorState/ErrorState";
import Header from "../common/header";
import Loading from "../common/loading/Loading";
import CreateStudent from "../home/students/CreateStudent";
import StudentListItem from "../home/students/StudentListItem";

class School extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    const schoolId = params.schoolId;
    this.closePanelRequested = this.closePanelRequested.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      openPanel: false,
      error: null,
      isLoaded: false,
      schoolId: schoolId,
      allStudents: [],
      reRender: false,
      schoolName: "",
      delete: null,
      edit: {},
      schoolDetails: null,
      coaches: [],
    };
  }

  componentDidMount() {
    this.loadContent();
  }

  async loadContent() {
    await this.fetchAllStudents();
    await this.fetchSchoolDetails();
  }

  closePanelRequested = async() => {
    this.setState({
      openPanel: false,
      edit: {},
    });
    await this.fetchAllStudents();
  };

  openPanel() {
    this.setState({ openPanel: true });
  }

  goBack() {
    this.props.history.goBack();
  }

  async fetchAllStudents() {
    const { schoolId } = this.state;
    const service = await SchoolService.getInstance();
    const response = await service.getAllStudents(schoolId);
    const { data } = response;
    if (response.success) {
      this.setState({
        allStudents: data,
        isLoaded: true,
        schoolName: data.length ? data[0].schoolName : "",
      });
    } else {
      this.setState({
        isLoaded: true,
        error: true,
      });
    }
  }

  async fetchSchoolDetails() {
    const { schoolId } = this.state;
    const service = await SchoolService.getInstance();
    const response = await service.getSchoolInfo(schoolId);
    const { data } = response;
    const coachList = data.coaches.map((el) => el.coachName);
    this.setState({
      schoolDetails: data,
      coaches: coachList.length ? coachList.toString() : "",
    });
  }

  editClicked(student) {
    this.setState({
      edit: student,
      openPanel: true,
    });
  }

  async deleteClicked(student) {
    const result = await alertService.show({
      title: "Confirm Delete",
      message: "Deleting an archer is permanent, continue?",
      primary: "Delete",
      secondary: "Cancel",
    });
    if (result) {
      this.deleteFromServer(student);
    }
  }

  async deleteFromServer({ userId }) {
    const service = await UserService.getInstance();
    const response = await service.deleteUser(userId);

    if (response.success) {
      this.loadContent();
    } else {
      alertService.show({
        title: "Delete Failed",
        message: "Unable to delete archer at this time, please try again.",
      });
    }
  }

  render() {
    const { error, isLoaded, allStudents, edit, schoolDetails, coaches } =
      this.state;
    if (error) {
      return (
        <ErrorState title="An error has occurred loading this content, please try again" />
      );
    } else {
      return (
        <div>
          <Header />
          <main>
            <div className="container">
              <div className="page-title">
                <div className="back" onClick={this.goBack}>
                  <img src={BackArrow} alt="" />
                  <span>Back</span>
                </div>
                <div className="page-title-add">
                  <button
                    className="btn visible"
                    onClick={() => this.openPanel()}
                  >
                    + Add Archers
                  </button>
                </div>
              </div>
              <SlidingPane
                className="pane"
                isOpen={this.state.openPanel}
                width="480px"
                hideHeader={true}
                onRequestClose={() => {
                  this.setState({ openPanel: false });
                }}
              >
                <CreateStudent
                  csv={Object.keys(edit).length ? false : true}
                  edit={Object.keys(edit).length ? true : false}
                  student={this.state.edit}
                  onClosePanel={this.closePanelRequested}
                />
              </SlidingPane>
              <div className="listing-block details-listing-block">
                <button className="listing-block-more"></button>
                <div className="thumb tournament-thumb">
                  <img src={TThumb} alt="" />
                </div>
                <div className="listing-block-wrapper">
                  <h3>{schoolDetails ? schoolDetails.name : ""}</h3>
                  <p>
                    School ID: {schoolDetails ? schoolDetails.externalId : ""}
                  </p>
                  <p>Sector: {schoolDetails ? schoolDetails.sector : ""}</p>
                  <p>Level: {schoolDetails ? schoolDetails.level : ""}</p>
                  <p>Division: {schoolDetails ? schoolDetails.division : ""}</p>
                  <p>Coach: {coaches.length ? coaches : "-"}</p>
                </div>
              </div>
              <div className="table-view">
                <div className="row head-row">
                  <div className="cols w-35">Archer Name</div>
                  <div className="cols w-35">School</div>
                  <div className="cols w-15">Archer ID</div>
                  <div className="cols w-15">Gender</div>
                  <div className="cols w-100p">&nbsp;</div>
                </div>
                {!isLoaded ? (
                  <Loading title="Loading..." />
                ) : (
                  <span>
                    {allStudents.map((item) => {
                      return (
                        <StudentListItem
                          student={item}
                          key={item.userId}
                          edit={() => this.editClicked(item)}
                          delete={() => this.deleteClicked(item)}
                        />
                      );
                    })}
                  </span>
                )}
              </div>
            </div>
          </main>
        </div>
      );
    }
  }
}

export default School;
