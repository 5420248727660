import React from "react";
import { AuthConsumer, AuthContext } from "../../../context/AuthContext";
import alertService from "../../../services/alert";
import SchoolService from "../../../services/SchoolService";
import UserService from "../../../services/UserService";
import { userRoles } from "../../../util/constants";
import Loading from "../../common/loading/Loading";

class CreateCoach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // coachError: false,
      // emailError: false,
      passwordError: false,
      schoolError: false,
      coach: props.coach ?? {
        role: userRoles.coach,
        name: null,
        email: null,
        password: null,
      },
      error: null,
      schoolsLoaded: false,
      schools: [],
      selectedSchoolIds: [],
      saving: false,
      edit: false,
      term: "",
      searchedSchools: [],
    };

    this.handleCoachChange = this.handleCoachChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount() {
    this.fetchSchools();
    if (this.props.edit && this.props.coach.userId) {
      this.fetchSchoolsForCoach(this.props.coach.userId);
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const { schools } = this.state;
    const search = e.target.value;
    this.setState({ term: e.target.value });
    let foundSchools = [];
    foundSchools = schools.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({
      searchedSchools: foundSchools.length ? foundSchools : schools,
    });
  };

  async fetchSchools() {
    const service = await SchoolService.getInstance();
    const response = await service.allSchools(0, 700);
    const { data } = response;
    if (response.success) {
      this.setState({
        error: false,
        schoolsLoaded: true,
        schools: data.content,
        searchedSchools: data.content,
      });
    } else {
      this.setState({
        error: true,
        schoolsLoaded: true,
      });
    }
  }

  async fetchSchoolsForCoach(userId) {
    const service = await UserService.getInstance();
    const response = await service.getSchoolsForUser(userId);
    const { data } = response;
    const ids = data.map((item) => item.schoolId);
    if (response.success) {
      this.setState({
        selectedSchoolIds: ids,
      });
    }
  }

  async saveToServer() {
    const { coach } = this.state;
    const updatedCoach = { ...coach };
    Object.keys(updatedCoach).map((k) => {
      if (["", undefined, null].includes(updatedCoach[k]))
        delete updatedCoach[k];
      return true;
    });
    const service = await UserService.getInstance();
    const response = await service.saveUser(coach);
    if (response.success) {
      if (this.context.user.userId === response.data.userId) {
        await this.context.register(response.data);
      }
      if (this.props.edit || this.state.edit) {
        this.props.onClosePanel(true);
      } else if (!this.props.edit && !this.state.edit) {
        this.setState({ coach: response.data, edit: true });
      }
    } else {
      this.props.onClosePanel(true);
    }
  }

  validate() {
    var success = true;

    if (!this.state.coach.name || this.state.coach.name.trim() === "") {
      this.setState({
        coachError: true,
      });
      success = false;
    } else {
      this.setState({
        coachError: false,
      });
    }

    // if (!this.state.coach.email || this.state.coach.email.trim() === "") {
    //   this.setState({
    //     emailError: true,
    //   });
    //   success = false;
    // } else {
    //   this.setState({
    //     emailError: false,
    //   });
    // }

    // if (!this.state.coach.password || this.state.coach.password.trim() === "") {
    //   if (
    //     this.state.coach.userId === null ||
    //     this.state.coach.userId === undefined
    //   ) {
    //     this.setState({
    //       passwordError: true,
    //     });
    //     success = false;
    //   } else {
    //     this.setState({
    //       passwordError: false,
    //     });
    //   }
    // } else {
    //   this.setState({
    //     passwordError: false,
    //   });
    // }

    return success;
  }

  submit() {
    if (this.validate()) {
      this.saveToServer();
    }
  }

  handleCoachChange({ target: { value } }) {
    var { coach } = this.state;
    coach.name = value.trim().length === 0 ? null : value;

    this.setState({
      coach: coach,
    });
  }

  handlePasswordChange({ target: { value } }) {
    var { coach } = this.state;
    coach.password = value.trim().length === 0 ? null : value;

    this.setState({
      coach: coach,
    });
  }

  handleEmailChange({ target: { value } }) {
    var { coach } = this.state;
    coach.email = value.trim().length === 0 ? null : value;

    this.setState({
      coach: coach,
    });
  }

  toggleSchool({ schoolId }) {
    const { selectedSchoolIds } = this.state;
    const index = selectedSchoolIds.indexOf(schoolId);
    if (index > -1) {
      this.removeCoachFromSchool(schoolId);
    } else {
      this.addCoachToSchool(schoolId);
    }
  }

  async addCoachToSchool(schoolId) {
    const { coach, selectedSchoolIds } = this.state;
    let ids = selectedSchoolIds;
    const coachToAdd = { coachId: coach.userId };
    const service = await SchoolService.getInstance();
    const response = await service.addCoachToSchool(schoolId, coachToAdd);
    if (response.success) {
      ids.push(schoolId);
      this.setState({ selectedSchoolIds: ids });
    } else {
      alertService.show({
        title: "Add School Failed",
        message: "Unable to save school, please try again.",
      });
    }
  }

  async removeCoachFromSchool(schoolId) {
    const { coach, selectedSchoolIds } = this.state;
    let ids = selectedSchoolIds;
    const index = selectedSchoolIds.indexOf(schoolId);
    const service = await SchoolService.getInstance();
    const response = await service.removeCoachFromSchool(
      schoolId,
      coach.userId
    );
    if (response.success) {
      ids.splice(index, 1);
      this.setState({ selectedSchoolIds: ids });
    } else {
      alertService.show({
        title: "Delete School Failed",
        message: "Unable to remove school, please try again.",
      });
    }
  }

  render() {
    const { searchedSchools, schoolsLoaded, selectedSchoolIds, saving, edit } =
      this.state;
    return (
      <section className="sidebar visible" id="add-coaches">
        <div className="sidebar-wrapper">
          <h3>{this.props.edit === true || edit ? "Edit" : "Add"} Coach</h3>
          <div className="sidebar-content-wrapper">
            <ul>
              <li>
                <label>Coach Name*</label>
                <input
                  type="text"
                  placeholder="Enter coach name"
                  value={this.state.coach.name ?? ""}
                  onChange={this.handleCoachChange}
                />
                {this.state.coachError === true && (
                  <div className="error">Coach name required</div>
                )}
              </li>
              <li>
                <label>Email Address</label>
                <input
                  type="text"
                  placeholder="Enter email address"
                  value={this.state.coach.email ?? ""}
                  onChange={this.handleEmailChange}
                />
                {/* {this.state.emailError === true && (
                  <div className="error">Email required</div>
                )} */}
              </li>
              <li>
                {/* <label>Password{this.state.coach.userId ? "" : "*"}</label> */}
                <label>Password</label>
                <input
                  type="text"
                  placeholder="Enter password here"
                  value={this.state.coach.password ?? ""}
                  onChange={this.handlePasswordChange}
                />
                {/* {this.state.passwordError === true && (
                  <div className="error">Password required</div>
                )} */}
              </li>
              <AuthConsumer>
                {({ user }) => (
                  <>
                    {(this.props.edit || edit) &&
                    user.role === userRoles.superadmin ? (
                      <li>
                        <label>Schools</label>
                        {!schoolsLoaded ? (
                          <Loading title="Loading..." />
                        ) : (
                          <div className="table-view">
                            <input
                              type="text"
                              placeholder="Search School"
                              value={this.state.term}
                              onChange={(e) => this.handleChange(e)}
                            />
                            {searchedSchools.map((item) => (
                              <div className="row" key={item.schoolId}>
                                <div className="cols w-100">
                                  {item.name}
                                  <div className="smaller">
                                    {item.sector} {item.level} {item.division}
                                  </div>
                                </div>
                                <div className="cols w-50p">
                                  <label className="custom-checkbox custom-checkbox-circle">
                                    <input
                                      type="checkbox"
                                      checked={selectedSchoolIds.includes(
                                        item.schoolId
                                      )}
                                      onChange={() => this.toggleSchool(item)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </li>
                    ) : null}
                  </>
                )}
              </AuthConsumer>
            </ul>
          </div>
          <div className="button-group">
            <button
              onClick={() => this.props.onClosePanel()}
              className="btn btn-cancel"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                !saving && this.submit();
              }}
              className="btn"
            >
              {saving ? "Saving.." : "Save"}
            </button>
          </div>
        </div>
      </section>
    );
  }
}

CreateCoach.contextType = AuthContext;
export default CreateCoach;
