import React from "react";
import StudentListItem from "./StudentListItem";
import Loading from "../../common/loading/Loading";
import ErrorState from "../../common/errorState/ErrorState";
import SlidingPane from "react-sliding-pane";
import CreateStudent from "../../home/students/CreateStudent";
import "react-sliding-pane/dist/react-sliding-pane.css";
import alertService from "../../../services/alert";
import UserService from "../../../services/UserService";
import { userRoles } from "../../../util/constants";

class Students extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isNext: false,
      isPrevious: false,
      totalPages: 0,
      page: 0,
      delete: null,
      edit: {},
      totalStudents: 0,
      students: [],
      showAlert: false,
      openPanel: false,
    };

    this.closePanelRequested = this.closePanelRequested.bind(this);
  }

  componentDidUpdate(props) {
    const { refresh } = this.props;
    if (props.refresh !== refresh) {
      this.fetchData(this.state.page);
    }
  }

  editClicked(student) {
    this.setState({
      edit: student,
      openPanel: true,
    });
  }

  closePanelRequested() {
    this.setState({
      openPanel: false,
      edit: {},
    });

    this.fetchData(this.state.page);
  }

  async deleteClicked(student) {
    const result = await alertService.show({
      title: "Confirm Delete",
      message: "Deleting an archer is permanent, continue?",
      primary: "Delete",
      secondary: "Cancel",
    });
    if (result) {
      this.deleteFromServer(student);
      this.setState({
        delete: student,
      });
    }
  }

  async deleteFromServer({ userId }) {
    const service = await UserService.getInstance();
    const response = await service.deleteUser(userId);

    if (response.success) {
      this.fetchData(this.state.page);
    } else {
      alertService.show({
        title: "Delete Failed",
        message: "Unable to delete archer at this time, please try again.",
      });
    }
  }

  async fetchData(pageNum) {
    const service = await UserService.getInstance();
    const response = await service.allUsers(userRoles.student, pageNum);
    const { data } = response;

    if (response.success) {
      this.setState({
        isLoaded: true,
        students: data.content,
        page: data.number,
        isNext: data.last !== true,
        isPrevious: data.first !== true,
        totalPages: data.totalPages,
        totalStudents: data.totalElements,
        delete: null,
        openPanel: false,
      });
    } else {
      this.setState({
        isLoaded: true,
        error: true,
      });
    }
  }

  componentDidMount() {
    this.fetchData(0);
  }

  render() {
    const { error, isLoaded, students } = this.state;
    if (error) {
      return (
        <ErrorState title="An error has occurred loading this content, please try again" />
      );
    } else if (!isLoaded) {
      return <Loading title="Loading..." />;
    } else {
      return (
        // <EmptyState title="There are no archers" message='There are currently no tournaments. Come back later'/>
        <div className="tab-content active-tab-content" id="students">
          <div className="section-title">
            <h5>{this.state.totalStudents} Archers</h5>
            <div className="pager-nav">
              {this.state.isPrevious === true && (
                <button
                  className="pager-nav-prev"
                  onClick={() => this.fetchData(this.state.page - 1)}
                >
                  Previous
                </button>
              )}
              Page {this.state.page + 1} of {this.state.totalPages}
              {this.state.isNext === true && (
                <button
                  className="pager-nav-next"
                  onClick={() => this.fetchData(this.state.page + 1)}
                >
                  Next
                </button>
              )}
            </div>
          </div>
          <div className="table-view">
            <div className="row head-row">
              <div className="cols w-35">Archer Name</div>
              <div className="cols w-35">School</div>
              <div className="cols w-15">Archer ID</div>
              <div className="cols w-15">Gender</div>
              <div className="cols w-100p">&nbsp;</div>
            </div>
            {students.map((item) => (
              <StudentListItem
                student={item}
                key={item.userId}
                edit={() => this.editClicked(item)}
                delete={() => this.deleteClicked(item)}
              />
            ))}
          </div>
          <SlidingPane
            className="pane"
            isOpen={this.state.openPanel}
            width="480px"
            hideHeader={true}
            // closeIcon={<div />}
            onRequestClose={() => {
              this.setState({ openPanel: false });
            }}
          >
            <CreateStudent
              csv={false}
              edit={true}
              student={this.state.edit}
              onClosePanel={this.closePanelRequested}
            />
          </SlidingPane>
        </div>
      );
    }
  }
}

export default Students;
