import React from "react";
import { AuthConsumer } from "../../context/AuthContext";
import { userRoles } from "../../util/constants";

export default class TournamentSchoolStudentsListItem extends React.Component {
  render() {
    const {
      session,
      isChecked,
      name,
      id,
      gender,
      toggleStudent,
      disqualifyStudent,
      disqualified,
      studentId,
      tournament,
    } = this.props;
    return (
      <div>
        <div className="row">
          <div className="cols w-30">{name}</div>
          {/* <div className="cols w-25">{school}</div> */}
          <div className="cols w-20">{id}</div>
          {this.props.gender === "M" && (
            <div className="cols w-20">{gender ? "Male" : "-"}</div>
          )}
          {this.props.gender === "F" && (
            <div className="cols w-20">{gender ? "Female" : "-"}</div>
          )}
          <div className="cols w-10">{session ? session.totalScore : "-"}</div>
          <div className="cols w-10">{session ? session.numTens : "-"}</div>
          <AuthConsumer>
            {({ user }) => (
              <div className="cols w-10 text-right">
                {user.role === userRoles.superadmin ||
                user.userId === tournament.ownerId ? (
                  <span>
                    {isChecked ? (
                      <span
                        className={
                          disqualified
                            ? "table-view-disqualified-link"
                            : "table-view-disqualify-link"
                        }
                        onClick={() =>
                          disqualifyStudent(name, studentId, !disqualified)
                        }
                      ></span>
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </span>
                ) : (
                  <span>&nbsp;</span>
                )}
                <label className="custom-checkbox custom-checkbox-circle">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() =>
                      toggleStudent(user.role, user.userId, studentId, name)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
          </AuthConsumer>
        </div>
      </div>
    );
  }
}
//export default TournamentSchoolStudentsListItem;
