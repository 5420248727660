import React from "react";
import { AuthConsumer } from "../../../context/AuthContext";
import { userRoles } from "../../../util/constants";

class CoachListItem extends React.Component {
  getSchools = () => {
    const { schools } = this.props.coach;
    let names = [];
    if (schools.length) {
      names = schools.map((item) => {
        const obj = `${item.schoolName} (${item.sector} ${item.level} ${item.division})`;
        return obj;
      });
    }
    const schoolName = names.length ? names.toString() : "-";
    return schoolName;
  };

  render() {
    const { name, email, userId } = this.props.coach;
    const schoolNames = this.getSchools();
    return (
      <AuthConsumer>
        {({ user }) => (
          <div className="row" style={{ alignItems: "normal" }}>
            <div className="cols w-15">{name}</div>
            <div className="cols w-55" style={{ whiteSpace: "unset" }}>
              {schoolNames}
            </div>
            <div className="cols w-30">{email === null ? "-" : email}</div>
            <div className="cols w-100p">
              {user.userId === userId || user.role === userRoles.superadmin ? (
                <div className="table-view-more">
                  <button
                    className="link table-view-edit-link"
                    onClick={this.props.edit}
                  ></button>
                  <button
                    className="link table-view-delete-link"
                    onClick={this.props.delete}
                  ></button>
                </div>
              ) : (
                <div className="table-view-more">&nbsp;</div>
              )}
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}
export default CoachListItem;
