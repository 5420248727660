import React from "react";
import SchoolListItem from "./SchoolListItem";
import EmptyState from "../../common/emptyState/EmptyState";
import Loading from "../../common/loading/Loading";
import ErrorState from "../../common/errorState/ErrorState";
import CreateSchool from "./CreateSchool";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import alertService from "../../../services/alert";
import SchoolService from "../../../services/SchoolService";
import {DebounceInput} from 'react-debounce-input';

class Schools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isNext: false,
      isPrevious: false,
      page: 0,
      edit: {},
      totalPages: 0,
      totalSchools: 0,
      openPanel: false,
      schools: [],
      searchedSchools: [],
      term: ""
    };

    this.closePanelRequested = this.closePanelRequested.bind(this);
  }

  handleChange = async(e) => {
    const { schools } = this.state;
    const search = e.target.value;
    this.setState({ term: e.target.value });
    if (search.length > 2) {
      const service = await SchoolService.getInstance();
      const response = await service.searchSchool(search);
      const { data } = response;
      this.setState({
        searchedSchools: data.length ? data : schools
      });
    } else {
      this.setState({
        searchedSchools: schools
      });
    }
  };

  componentDidUpdate(props) {
    const { refresh } = this.props;
    if (props.refresh !== refresh) {
      this.fetchData(this.state.page);
    }
  }

  componentDidMount() {
    this.fetchData(0);
  }

  editClicked(school) {
    this.setState({
      edit: school,
      openPanel: true,
    });
  }

  closePanelRequested() {
    this.setState({
      openPanel: false,
      edit: {},
    });
  }

  async deleteClicked(school) {
    const result = await alertService.show({
      title: "Confirm Delete",
      message: "Deleting a school is permanent, continue?",
      primary: "Delete",
      secondary: "Cancel",
    });
    if (result) {
      this.deleteFromServer(school);
    }
  }

  async deleteFromServer({ schoolId }) {
    const service = await SchoolService.getInstance();
    const response = await service.deleteSchool(schoolId);

    if (response.success) {
      this.fetchData(this.state.page);
    } else {
      alertService.show({
        title: "Delete Failed",
        message: "Unable to delete school at this time, please try again.",
      });
    }
  }

  async fetchData(pageNum) {
    const service = await SchoolService.getInstance();
    const response = await service.allSchools(pageNum);
    const { data } = response;

    if (response.success) {
      this.setState({
        isLoaded: true,
        schools: data.content,
        searchedSchools: data.content,
        page: data.number,
        isNext: data.last !== true,
        isPrevious: data.first !== true,
        totalPages: data.totalPages,
        totalSchools: data.totalElements,
        openPanel: false,
      });
    } else {
      this.setState({
        error: true,
        isLoaded: true,
      });
    }
  }

  render() {
    const { error, isLoaded, schools, searchedSchools } = this.state;
    if (error) {
      return (
        <ErrorState title="An error has occurred loading this content, please try again" />
      );
    } else if (!isLoaded) {
      return <Loading title="Loading..." />;
    } else {
      return (
        // <EmptyState title="There are no schools" message='There are currently no schools. Come back later'/>
        // <ErrorState title="An error has occurred loading this content, please try again" />
        // <Loading title="Loading..." />
        <div className="tab-content active-tab-content" id="schools">
          <div style={{ marginBottom: '40px' }}>
            <DebounceInput
              minLength={3}
              placeholder="Search Schools..."
              style={{ width: '350px', borderRadius: '5px' }}
              debounceTimeout={1000}
              onChange={(e) => this.handleChange(e)}/>
            </div>
          <div className="section-title">
            <h5>{this.state.totalSchools} Schools</h5>
            <div className="pager-nav">
              {this.state.isPrevious === true && (
                <button
                  className="pager-nav-prev"
                  onClick={() => this.fetchData(this.state.page - 1)}
                >
                  Previous
                </button>
              )}
              Page {this.state.page + 1} of {this.state.totalPages}
              {this.state.isNext === true && (
                <button
                  className="pager-nav-next"
                  onClick={() => this.fetchData(this.state.page + 1)}
                >
                  Next
                </button>
              )}
            </div>
          </div>
          <div className="table-view">
            <div className="row head-row">
              <div className="cols w-40">School Name</div>
              <div className="cols w-15">School ID</div>
              <div className="cols w-15">Sector</div>
              <div className="cols w-15">Level</div>
              <div className="cols w-15">Division</div>
              <div className="cols w-100p">&nbsp;</div>
            </div>
            {searchedSchools.map((item) => (
              <SchoolListItem
                school={item}
                key={item.schoolId}
                edit={() => this.editClicked(item)}
                delete={() => this.deleteClicked(item)}
              />
            ))}
            {schools.length === 0 && (
              <EmptyState
                title="There are no schools"
                message="There are currently no schools. Come back later"
              />
            )}
          </div>
          <SlidingPane
            className="pane"
            isOpen={this.state.openPanel}
            width="480px"
            hideHeader={true}
            // closeIcon={<div />}
            onRequestClose={() => {
              this.setState({ openPanel: false });
            }}
          >
            {/* This should be Add Student component */}
            <CreateSchool
              edit={true}
              school={this.state.edit}
              onClosePanel={this.closePanelRequested}
            />
          </SlidingPane>
        </div>
      );
    }
  }
}

export default Schools;
