import React from "react";
import { AuthConsumer } from "../../../context/AuthContext";
import { userRoles } from "../../../util/constants";

class StudentListItem extends React.Component {
  render() {
    return (
      <AuthConsumer>
        {({ user }) => (
          <div className="row">
            <div className="cols w-35">{this.props.student.name}</div>
            <div className="cols w-35">
              {this.props.student.schoolName === null
                ? "-"
                : this.props.student.schoolName}
            </div>
            <div className="cols w-15">
              {this.props.student.externalId === null
                ? "-"
                : this.props.student.externalId}
            </div>
            {this.props.student.gender === "M" && (
              <div className="cols w-15">
                {this.props.student.gender === null ? "-" : "Male"}
              </div>
            )}
            {this.props.student.gender === "F" && (
              <div className="cols w-15">
                {this.props.student.gender === null ? "-" : "Female"}
              </div>
            )}
            {((user.schools && user.schools.filter(s => s.schoolId === this.props.student.schoolId).length > 0) ||
              user.role === userRoles.superadmin) && (
              <div className="cols w-100p">
                <div className="table-view-more">
                  <button
                    className="link table-view-edit-link"
                    onClick={this.props.edit}
                  ></button>
                  <button
                    className="link table-view-delete-link"
                    onClick={this.props.delete}
                  ></button>
                </div>
              </div>
            )}
            {user.schoolId !== this.props.student.schoolId && (
              <div className="cols w-100p">
                <div className="table-view-more">&nbsp;</div>
              </div>
            )}
          </div>
        )}
      </AuthConsumer>
    );
  }
}
export default StudentListItem;
