import React from "react";
// import EmptyState from '../common/emptyState/EmptyState';
import Loading from "../common/loading/Loading";
import ErrorState from "../common/errorState/ErrorState";
import TournamentSchoolStudentsListItem from "../tournament/TournamentSchoolStudentsListItem";
import SchoolService from "../../services/SchoolService";
import TournamentService from "../../services/TournamentService";
import alertService from "../../services/alert";
import { userRoles } from "../../util/constants";

class TournamentSchoolStudents extends React.Component {
  constructor(props) {
    super(props);

    // Get our details from the URL
    const {
      match: { params },
    } = this.props;
    const tournamentId = params.tournamentId;
    const schoolId = params.schoolId;

    this.state = {
      error: null,
      isLoaded: false,
      tournamentId: tournamentId,
      schoolId: schoolId,
      schoolName: "",
      allStudents: [],
      coachNames: [],
      selectedStudentIds: [],
      disqualifiedStudentIds: [],
      schoolDetail: null,
      studentsInTournament: [],
    };
  }

  componentDidMount() {
    this.loadContent();
  }

  async loadContent() {
    await this.fetchSchoolDetails();
    await this.fetchCoaches();
    await this.fetchAllStudents();
    await this.fetchAllStudentsInTournament();
  }

  async fetchSchoolDetails() {
    const { schoolId, tournamentId } = this.state;
    const service = await TournamentService.getInstance();
    const response = await service.fetchSchoolDetailsInTournament(
      tournamentId,
      schoolId
    );
    const { data } = response;
    if (response.success) {
      this.setState({
        schoolDetail: data,
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  async fetchCoaches() {
    const { schoolId } = this.state;

    const service = await SchoolService.getInstance();
    const response = await service.getCoaches(schoolId);
    const { data } = response;

    if (response.success) {
      var coachNames = [];
      for (let i = 0; i < data.length; i++) {
        coachNames.push(data[i].name);
      }
      this.setState({
        coachNames: coachNames.join(", "),
        isLoaded: true,
      });
    } else {
      this.setState({
        isLoaded: true,
        error: true,
      });
    }
  }

  async fetchAllStudents() {
    const { schoolId } = this.state;

    const service = await SchoolService.getInstance();
    const response = await service.getAllStudents(schoolId);
    const { data } = response;

    if (response.success) {
      this.setState({
        allStudents: data,
        isLoaded: true,
        schoolName: data[0].schoolName,
      });
    } else {
      this.setState({
        isLoaded: true,
        error: true,
      });
    }
  }

  async fetchAllStudentsInTournament() {
    const { schoolId, tournamentId } = this.state;

    const service = await TournamentService.getInstance();
    const response = await service.getStudentsForSchool(tournamentId, schoolId);
    const { data } = response;
    if (response.success) {
      let tournamentStudentIds = [];
      let disqualifiedIds = [];
      for (var i = 0; i < data.length; i++) {
        tournamentStudentIds.push(data[i].userId);
        if (data[i].disqualified) disqualifiedIds.push(data[i].userId);
      }
      this.setState({
        selectedStudentIds: tournamentStudentIds,
        disqualifiedStudentIds: disqualifiedIds,
        studentsInTournament: data,
      });
    } else {
      this.setState({
        isLoaded: true,
        error: true,
      });
    }
  }

  toggleStudent = async (role, userId, studentId, name) => {
    const { selectedStudentIds, tournamentId } = this.state;
    const { tournament } = this.props;
    const service = await TournamentService.getInstance();
    const index = selectedStudentIds.indexOf(studentId);
    let response;
    let actionCalled = false;
    if (index > -1) {
      if (role === userRoles.superadmin || userId === tournament.ownerId) {
        if (
          this.props.tournamentStatus &&
          this.props.tournamentStatus !== "Upcoming"
        ) {
          const result = await alertService.show({
            title: "Remove Archer?",
            message: `Removing ${name} from the tournament will also permanently remove any scores that they may have recorded.`,
            primary: "Remove",
            secondary: "Cancel",
          });
          if (result) {
            response = await service.removeStudent(tournamentId, studentId);
            actionCalled = true;
          }
        } else {
          response = await service.removeStudent(tournamentId, studentId);
          actionCalled = true;
        }
      } else {
        alertService.show({
          title: "Permission Denied",
          message:
            "You are not allowed to remove archer because you are not the owner.",
        });
      }
    } else {
      response = await service.addStudents(tournamentId, [studentId]);
      actionCalled = true;
    }

    if (response && response.success) {
      await this.fetchAllStudentsInTournament();
    } else if (actionCalled) {
      alertService.show({
        title: "Action Failed",
        message:
          "Unable to update participants at this time, please try again.",
      });
    }
  };

  disqualifyStudent = async (studentName, studentId, disableStatus) => {
    const { tournamentId } = this.state;
    const service = await TournamentService.getInstance();
    if (disableStatus) {
      const result = await alertService.show({
        title: "Confirm Disqualify",
        message: `Are you sure you want to disqualify ${studentName} from this tournament?`,
        primary: "Disqualify",
        secondary: "Cancel",
      });
      if (result) {
        const response = await service.disableStudent(
          tournamentId,
          studentId,
          disableStatus
        );
        if (response.success) this.fetchAllStudentsInTournament();
      }
    } else {
      const response = await service.disableStudent(
        tournamentId,
        studentId,
        disableStatus
      );
      if (response.status) this.fetchAllStudentsInTournament();
    }
  };

  render() {
    const {
      studentsInTournament,
      error,
      isLoaded,
      allStudents,
      selectedStudentIds,
      disqualifiedStudentIds,
      schoolDetail,
    } = this.state;
    if (error) {
      return (
        <ErrorState title="An error has occurred loading this content, please try again" />
      );
    } else if (!isLoaded) {
      return <Loading title="Loading..." />;
    } else {
      return (
        // <EmptyState title="There are no archers" message='There are currently no archers. Come back later'/>
        <div className="tabs-wrapper">
          <div className="tab-content active-tab-content" id="students">
            <div className="section-title">
              <div className="section-title-left">
                <h5>
                  <span>School: </span>
                  {schoolDetail.name}
                </h5>
                <h5>
                  <span>Division: </span>
                  {schoolDetail.sector} {schoolDetail.level}{" "}
                  {schoolDetail.division}
                </h5>
                <h5>
                  <span>Coach: </span>
                  {schoolDetail.coachName ? schoolDetail.coachName : ""}
                </h5>
              </div>
            </div>
            <div className="table-view">
              <div className="row head-row">
                <div className="cols w-30">Archer Name</div>
                {/* <div className="cols w-25">School</div> */}
                <div className="cols w-20">Archer ID</div>
                <div className="cols w-20">Gender</div>
                <div className="cols w-10">Score</div>
                <div className="cols w-10">Tens</div>
                <div className="cols w-10">&nbsp;</div>
              </div>
              {allStudents.map((item) => {
                const isChecked = selectedStudentIds.includes(item.userId);
                const isDisqualified = disqualifiedStudentIds.includes(
                  item.userId
                );
                const session = studentsInTournament.find(
                  (student) => student.userId === item.userId
                );
                return (
                  <TournamentSchoolStudentsListItem
                    name={item.name}
                    school={item.schoolName}
                    id={item.externalId ? item.externalId : "-"}
                    gender={item.gender}
                    key={`${item.userId}${isChecked}${new Date()}`}
                    toggleStudent={this.toggleStudent}
                    isChecked={isChecked}
                    disqualified={isDisqualified}
                    disqualifyStudent={this.disqualifyStudent}
                    studentId={item.userId}
                    tournament={this.props.tournament}
                    session={
                      session !== undefined ? session.tournamentSession : null
                    }
                  />
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TournamentSchoolStudents;
