import React from "react";
import TournamentListItem from "./TournamentListItem";
import EmptyState from "../../common/emptyState/EmptyState";
import Loading from "../../common/loading/Loading";
import ErrorState from "../../common/errorState/ErrorState";
import moment from "moment";
import SlidingPane from "react-sliding-pane";
import CreateTournament from "./CreateTournament";
import "react-sliding-pane/dist/react-sliding-pane.css";
import alertService from "../../../services/alert";
import TournamentService from "../../../services/TournamentService";
import { AuthConsumer } from "../../../context/AuthContext";

class Tournaments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: false,
      isNext: false,
      isPrevious: false,
      totalPages: 0,
      page: 0,
      totalTournaments: 0,
      tournaments: [],
      delete: null,
      edit: {},
      openPanel: false,
    };

    this.closePanelRequested = this.closePanelRequested.bind(this);
  }

  componentDidUpdate(props) {
    const { refresh } = this.props;
    if (props.refresh !== refresh) {
      this.fetchData(this.state.page);
    }
  }

  inProgressTournaments() {
    const now = moment();
    return this.state.tournaments.filter(
      (tournament) =>
        tournament.endDate &&
        now.isAfter(moment(tournament.startDate)) &&
        now.isBefore(moment(tournament.endDate))
    );
  }

  upcomingTournaments() {
    const now = moment();
    return this.state.tournaments.filter(
      (tournament) =>
        moment(tournament.startDate).isAfter(now) ||
        moment(tournament.startDate).isSame(now)
    );
  }

  pastTournaments() {
    const now = moment();
    return this.state.tournaments.filter((tournament) =>
      now.isAfter(moment(tournament.endDate ?? tournament.startDate))
    );
  }

  closePanelRequested() {
    this.setState({
      openPanel: false,
      edit: {},
    });

    this.fetchData(this.state.page);
  }

  editClicked(tournament) {
    this.setState({
      edit: tournament,
      openPanel: true,
    });
  }

  async deleteClicked(tournament) {
    const result = await alertService.show({
      title: "Confirm Delete",
      message: "Deleting a tournament is permanent, continue?",
      primary: "Delete",
      secondary: "Cancel",
    });
    if (result) {
      this.deleteFromServer(tournament);
    }
  }

  async deleteFromServer({ tournamentId }) {
    const service = await TournamentService.getInstance();
    const response = await service.deleteTournament(tournamentId);

    if (response.success) {
      this.fetchData(this.state.page);
    } else {
      alertService.show({
        title: "Delete Failed",
        message: "Unable to delete tournament at this time, please try again.",
      });
    }
  }

  async fetchData(pageNum) {
    const service = await TournamentService.getInstance();
    const response = await service.allTournaments();
    const { data } = response;

    if (response.success) {
      this.setState({
        error: false,
        isLoaded: true,
        page: data.number,
        isNext: data.last !== true,
        isPrevious: data.first !== true,
        totalPages: data.totalPages,
        totalTournaments: data.totalElements,
        tournaments: data.content,
      });
    } else {
      this.setState({
        error: true,
        isLoaded: true,
      });
    }
  }

  componentDidMount() {
    this.fetchData(0);
  }

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return (
        <ErrorState title="An error has occurred loading this content, please try again" />
      );
    } else if (!isLoaded) {
      return <Loading title="Loading..." />;
    } else {
      const inProgressTournaments = this.inProgressTournaments();
      const upcomingTournaments = this.upcomingTournaments();
      const pastTournaments = this.pastTournaments();

      return (
        <div>
          <main>
            <div className="tab-content active-tab-content" id="tournaments">
              <div className="listing-blocks">
                {inProgressTournaments.length > 0 ? (
                  inProgressTournaments.map((item) => (
                    <TournamentListItem
                      tournament={item}
                      key={item.tournamentId}
                      tournamentState={"In Progress"}
                      edit={() => this.editClicked(item)}
                      delete={() => this.deleteClicked(item)}
                    />
                  ))
                ) : (
                  <EmptyState
                    title="There are no tournaments in progress"
                    message="There are currently no tournaments in progress. Come back later"
                  />
                )}
              </div>
              <div className="section-title">
                <h5>{upcomingTournaments.length} Upcoming Tournaments</h5>
              </div>
              <div className="listing-blocks">
                {upcomingTournaments.length > 0 ? (
                  upcomingTournaments.map((item) => (
                    <TournamentListItem
                      tournament={item}
                      key={item.tournamentId}
                      edit={() => this.editClicked(item)}
                      delete={() => this.deleteClicked(item)}
                      tournamentState={"Upcoming"}
                    />
                  ))
                ) : (
                  <EmptyState
                    title="There are no upcoming tournaments"
                    message="There are currently no upcoming tournaments. Come back later"
                  />
                )}
              </div>
              <div className="section-title">
                <h5>{pastTournaments.length} Past Tournament</h5>
              </div>
              <div className="listing-blocks">
                {pastTournaments.length > 0 ? (
                  pastTournaments.map((item) => (
                    <TournamentListItem
                      tournament={item}
                      key={item.tournamentId}
                      tournamentState={"Completed"}
                      edit={() => this.editClicked(item)}
                      delete={() => this.deleteClicked(item)}
                    />
                  ))
                ) : (
                  <EmptyState
                    title="There are no past tournaments"
                    message="There are currently no past tournaments. Come back later"
                  />
                )}
              </div>
            </div>
            <SlidingPane
              className="pane"
              isOpen={this.state.openPanel}
              width="480px"
              hideHeader={true}
              // closeIcon={<div />}
              onRequestClose={() => {
                this.setState({ openPanel: false });
              }}
            >
              <AuthConsumer>
                {({ user }) => (
                  <CreateTournament
                    user={user}
                    edit={true}
                    tournament={this.state.edit}
                    onClosePanel={this.closePanelRequested}
                  />
                )}
              </AuthConsumer>
            </SlidingPane>
          </main>
        </div>
      );
    }
  }
}

export default Tournaments;
