import React from "react";

import Coaches from "./coaches";
import Students from "./students";
import Schools from "./schools";
import Tournaments from "./tournaments";
import Navigation from "../common/navigation";
import Header from "../common/header";

export const homeTabs = {
  TOURNAMENTS: "tournaments",
  SCHOOLS: "schools",
  STUDENTS: "archers",
  COACHES: "coaches",
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    const section = params.section;
    this.state = {
      currentTab: this._tabForSectionString(section),
      refreshFlag: new Date(),
    };

    this.refreshContent = this.refreshContent.bind(this);
  }

  _tabForSectionString(section) {
    switch (section) {
      case "schools":
        return homeTabs.SCHOOLS;
      case "archers":
        return homeTabs.STUDENTS;
      case "coaches":
        return homeTabs.COACHES;
      default:
        return homeTabs.TOURNAMENTS;
    }
  }

  componentDidUpdate() {
    const {
      match: { params },
    } = this.props;
    const tab = this._tabForSectionString(params.section);
    if (tab !== this.state.currentTab) {
      this.setState({ currentTab: tab });
    }
  }

  refreshContent() {
    // Forcing a refresh
    this.setState({
      refreshFlag: new Date(),
    });
  }

  render() {
    const { currentTab, refreshFlag } = this.state;

    return (
      <div className="home">
        <Header />
        <Navigation
          currentTab={currentTab}
          refreshContent={this.refreshContent}
        />
        <div className="container">
          {currentTab === homeTabs.TOURNAMENTS && (
            <Tournaments refresh={refreshFlag} />
          )}

          {currentTab === homeTabs.SCHOOLS && <Schools refresh={refreshFlag} />}

          {currentTab === homeTabs.STUDENTS && (
            <Students refresh={refreshFlag} />
          )}

          {currentTab === homeTabs.COACHES && <Coaches refresh={refreshFlag} />}
        </div>
      </div>
    );
  }
}

export default Home;
